// src/app/video-hover.directive.ts
import { Directive, ElementRef, HostListener } from '@angular/core';

declare var YT: any;

@Directive({
  selector: '[appVideoHover]'
})
export class VideoHoverDirective {
  private player: any;

  constructor(private el: ElementRef) {}

  // Initialize the YouTube player
  ngAfterViewInit() {
    this.player = new YT.Player(this.el.nativeElement, {
      events: {
        'onReady': this.onPlayerReady.bind(this)
      }
    });
  }

  // Player ready handler
  onPlayerReady(event: any) {
    // Player is ready
  }

  @HostListener('mouseenter') onMouseEnter() {
    this.player.playVideo();
  }

  @HostListener('mouseleave') onMouseLeave() {
    this.player.pauseVideo();
  }
}
