import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-consent',
  templateUrl: './consent.component.html',
  styleUrls: ['./consent.component.css'] // Fixed 'styleUrl' to 'styleUrls'
})
export class ConsentComponent implements OnInit {
  showBanner = false;
  showSettingsPanel = false;

  performanceCookies = false;
  functionalCookies = false;
  targetingCookies = false;

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {}

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      const consent = this.getCookie('ICLS_CC');
      if (!consent) {
        setTimeout(() => {
          this.showBanner = true;
        }, 500); // Display banner after a delay (can be adjusted)
      }
    }
  }

  acceptCookies(): void {
    this.setCookie('ICLS_CC', 'customized', 365);
    this.showBanner = false;
  }

  declineCookies(): void {
    this.showBanner = false;
  }

  showCustomSettings(): void {
    this.showSettingsPanel = true;
  }

  hideCustomSettings(): void {
    this.showSettingsPanel = false;
  }

  confirmSelection(): void {
    this.setCookie('ICLS_CC', 'customized', 365);
    this.showSettingsPanel = false;
    this.showBanner = false;
  }

  private setCookie(name: string, value: string, days: number): void {
    if (isPlatformBrowser(this.platformId)) {
      const date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      const expires = `expires=${date.toUTCString()}`;
      document.cookie = `${name}=${value};${expires};path=/`;
    }
  }

  private getCookie(name: string): string | null {
    if (isPlatformBrowser(this.platformId)) {
      const nameEQ = `${name}=`;
      const ca = document.cookie.split(';');
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
      }
    }
    return null;
  }

  private deleteCookie(name: string): void {
    if (isPlatformBrowser(this.platformId)) {
      document.cookie = `${name}=; Max-Age=-99999999;`;
    }
  }
}
