<footer class="container-fluid bg-dark text-white">
    <div class="row text-start justify-content-between">

        <div class="col-lg-3 col-md-12 order-lg-1 order-1">
            <a class="navbar-brand" href="#">
                <img src="../../../assets/imgs/logos/LogoWhite.png" alt="Company Logo" class="img-fluid">
            </a>
            <div class="followDiv" *ngIf="!isMobile">
                <h4>Follow Us</h4>
                <a *ngFor="let icon of icons" [href]="icon.route" target="_blank"
                    class="text-secondary me-2 img-fluid me-1" (mouseenter)="changeIconPath(icon, true)"
                    (mouseleave)="changeIconPath(icon, false)">
                    <img [src]="icon.path" alt="Airline Logo" width="25px">
                </a>
            </div>
        </div>

        <div class="col-lg-9 col-md-12 order-lg-2 order-2">

            <mat-accordion multi="true" *ngIf="isMobile">
                <!-- Example for one column, repeat for others -->
                <mat-expansion-panel #Consolidation>
                    <mat-expansion-panel-header style="border-bottom: 1px solid #aaaaaa;">
                        <mat-panel-title>
                            Consolidation
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <ul class="list-unstyled">
                        <li><a routerLink="/freight/sea" class="link-light">Weekly & Fortnightly LCL</a></li>
                        <li><a routerLink="/freight/sea" class="link-light">Worldwide FCL</a></li>
                        <li><a routerLink="/freight/sea" class="link-light">Import LCL & FCL</a></li>
                        <li><a routerLink="/MyIntercargo/sailing-schedules" class="link-light">Sailing Schedules</a></li>
                    </ul>
                </mat-expansion-panel>
                <!-- Repeat for other columns -->
                <mat-expansion-panel #Air>
                    <mat-expansion-panel-header style="border-bottom: 1px solid #aaaaaa;">
                        <mat-panel-title>
                            Air Freight
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <ul class="list-unstyled">
                        <li><a routerLink="/freight/london" class="link-light">To London</a></li>
                        <li><a routerLink="/freight/australia" class="link-light">To Australia</a></li>
                        <li><a routerLink="/freight/hongkong" class="link-light">To Hong Kong</a></li>
                        <li><a routerLink="/freight/singapore" class="link-light">To Singapore</a></li>
                    </ul>
                </mat-expansion-panel>

                <mat-expansion-panel #Sea>
                    <mat-expansion-panel-header style="border-bottom: 1px solid #aaaaaa;">
                        <mat-panel-title>
                            Sea Freight
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <ul class="list-unstyled">
                        <li><a routerLink="/freight/dubai" class="link-light">To Dubai</a></li>
                        <li><a routerLink="/freight/canada" class="link-light">To Canada</a></li>
                        <li><a routerLink="/freight/usa" class="link-light">To USA</a></li>
                        <li><a routerLink="/freight/china" class="link-light">To China</a></li>
                    </ul>
                </mat-expansion-panel>

                <mat-expansion-panel #Road>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Road Freight
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <ul class="list-unstyled">
                        <li><a routerLink="/freight/belgium" class="link-light">To Belgium</a></li>
                        <li><a routerLink="/freight/spain" class="link-light">To Spain</a></li>
                        <li><a routerLink="/freight/france" class="link-light">To France</a></li>
                        <li><a routerLink="/freight/ireland" class="link-light">To Ireland</a></li>
                    </ul>
                </mat-expansion-panel>

            </mat-accordion>

            <div class="d-flex justify-content-between" *ngIf="!isMobile">

                <div class="col-md-3">
                    <h4 class="heading">Consolidation</h4>
                    <ul class="list-unstyled ">
                        <li><a routerLink="/freight/sea" class="link-light">Weekly & Fortnightly LCL</a></li>
                        <li><a routerLink="/freight/sea" class="link-light">Worldwide FCL</a></li>
                        <li><a routerLink="/freight/sea" class="link-light">Import LCL & FCL</a></li>
                        <li><a routerLink="/MyIntercargo/sailing-schedules" class="link-light">Sailing Schedules</a></li>
                    </ul>
                </div>
                <div class="col-md-2">
                    <h4 class="heading">Air Freight</h4>
                    <ul class="list-unstyled">
                        <li><a routerLink="/freight/london" class="link-light">To London</a></li>
                        <li><a routerLink="/freight/australia" class="link-light">To Australia</a></li>
                        <li><a routerLink="/freight/hongkong" class="link-light">To Hong Kong</a></li>
                        <li><a routerLink="/freight/singapore" class="link-light">To Singapore</a></li>
                    </ul>
                </div>
                <div class="col-md-2">
                    <h4 class="heading">Sea Freight</h4>
                    <ul class="list-unstyled">
                        <li><a routerLink="/freight/dubai" class="link-light">To Dubai</a></li>
                        <li><a routerLink="/freight/canada" class="link-light">To Canada</a></li>
                        <li><a routerLink="/freight/usa" class="link-light">To USA</a></li>
                        <li><a routerLink="/freight/china" class="link-light">To China</a></li>
                    </ul>
                </div>
                <div class="col-md-2">
                    <h4 class="heading">Road Freight</h4>
                    <ul class="list-unstyled">
                        <li><a routerLink="/freight/belgium" class="link-light">To Belgium</a></li>
                        <li><a routerLink="/freight/spain" class="link-light">To Spain</a></li>
                        <li><a routerLink="/freight/france" class="link-light">To France</a></li>
                        <li><a routerLink="/freight/ireland" class="link-light">To Ireland</a></li>
                    </ul>
                </div>

                <div class="col-md-2 text-end">
                    <button (click)="gotoTop($event)" class="btn btn-outline-light">Go to Top <i
                            class="bi bi-arrow-up"></i></button>
                </div>
            </div>

        </div>

        <div class="followDiv order-3 mt-5 ps-4" *ngIf="isMobile">
            <h4><strong>Follow Us</strong></h4>
            <a *ngFor="let icon of icons" [href]="icon.route" target="_blank" class="text-secondary me-2 img-fluid me-1"
                (mouseenter)="changeIconPath(icon, true)" (mouseleave)="changeIconPath(icon, false)">
                <img [src]="icon.path" alt="Airline Logo" width="25px">
            </a>
        </div>


    </div>
    <div class="col-md-2 text-end">
        <button (click)="gotoTop($event)" class="btn btn-outline-light d-lg-none d-md-none">Go to Top <i
                class="bi bi-arrow-up"></i></button>
    </div>
    <div class="footer-bottom justify-content-between mt-5 pt-5">
        <p>© Intercargo. All Rights Reserved.</p>
        <p><a routerLink="/privacy-policy" class="link-light text-decoration-none">Intercargo Privacy Policy</a> | <a
                href="#" class="link-light text-decoration-none" routerLink="/terms&conditions">Terms & Conditions</a>
        </p>
        <p>All business subject to BIFA standard trading terms and conditions</p>
    </div>

</footer>