import { Component, EventEmitter, Inject, Output} from '@angular/core';
import { Router , ActivatedRoute} from '@angular/router';
@Component({
  selector: 'app-thankyoumodal',
  templateUrl: './thankyoumodal.component.html',
  styleUrls: ['../downloadpdf-thankyou/downloadpdf-thankyou.component.css'], })
export class ThankyoumodalComponent {
  @Output() modalClose: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private router : Router,
    private activatedRoute: ActivatedRoute
  ) {
  }
  
  
  closeModal( $event: any ) {
    this.router.navigate([{outlets: {modal: null}}]);
    this.modalClose.next($event);
  }
}
