import { Component, ElementRef, ViewChild } from '@angular/core';
import { DarkModeService } from '../../services/dark-mode.service';
import { Subscription } from 'rxjs';
import { Meta, Title } from '@angular/platform-browser';


@Component({
  selector: 'app-terms-and-condtions',
  templateUrl: './terms-and-condtions.component.html',
  styleUrl: './terms-and-condtions.component.css',
})
export class TermsAndCondtionsComponent {
  @ViewChild('faqList') faqList!: ElementRef;
  isDisabled = true;
  activeItem = 'definitions';
  imageSrc: string | undefined = '../../../assets/imgs/Accreditations/Bifalight.png';
  enableSearchButton() {
    this.isDisabled = false;
  }
  private subscription: Subscription;

  constructor(private darkModeService: DarkModeService,private title: Title, private meta: Meta) {
    this.subscription = this.darkModeService.darkMode$.subscribe(
      (isDarkMode) => {
        this.imageSrc = isDarkMode
          ? '../../../assets/imgs/Accreditations/Bifawhite.png'
          : '../../../assets/imgs/Accreditations/Bifalight.png';
      }
    );
  }
  policy = {
    heading: 'Definitions and Application',
    description: `<p class="mt-4 lh-lg" style="width: 100% !important" >
              <span class="fw-semibold" >1-</span>&nbsp;&nbsp;In these conditions the following words shall have
              the following meanings:-
              <br />
              <br />
            </p>
            <table class="table table-bordered"  >
              <thead class="thead-dark"></thead>
              <tbody   >
                <tr >
                  <td class="text-primary bg-primary">“Company”</td>
                  <td class="text-primary bg-primary">the BIFA member trading under these conditions</td>
                </tr>
                <tr>
                  <td class="text-primary bg-primary">“Consignee”</td>
                  <td class="text-primary bg-primary">the Person to whom the goods are consigned</td>
                </tr>
                <tr>
                  <td class="text-primary bg-primary">“Customer”</td>
                     <td class="text-primary bg-primary" >
                    any Person at whose request or on whose behalf the Company
                    undertakes any business or provides advice, information or
                    services
                  </td>
                </tr>
                <tr>
                     <td class="text-primary bg-primary" >“Direct Customs Agent”</td>
                     <td class="text-primary bg-primary" >
                    the Company acting in the name of and on behalf of the
                    Customer and/or Owner with H.M. Revenue and Customs (“HMRC”)
                    as defined by the Taxation (Cross Border Trade) Act 2018,
                    Clause 21.1(a), or as amended
                  </td>
                </tr>
                <tr>
                     <td class="text-primary bg-primary" >“Goods”</td>
                     <td class="text-primary bg-primary" >
                    the cargo to which any business under these conditions
                    relates
                  </td>
                </tr>
                <tr>
                     <td class="text-primary bg-primary" >“LMAA”</td>
                     <td class="text-primary bg-primary" >the London Maritime Arbitrators Association</td>
                </tr>
                <tr>
                     <td class="text-primary bg-primary" >“SDR”</td>
                     <td class="text-primary bg-primary" >
                    are Special Drawing Rights as defined by the International
                    Monetary Fund
                  </td>
                </tr>
                <tr>
                     <td class="text-primary bg-primary" >“Transport Unit”</td>
                     <td class="text-primary bg-primary" >
                    packing case, pallets, container, trailer, tanker, or any
                    other device used whatsoever for and in connection with the
                    carriage of Goods by land, sea or air
                  </td>
                </tr>
                <tr>
                     <td class="text-primary bg-primary" >“Owner”</td>
                     <td class="text-primary bg-primary" >
                    the Owner of the Goods or Transport Unit and any other
                    Person who is or may become interested in them
                  </td>
                </tr>
              </tbody>
            </table>
            <p class="mt-4 lh-lg" style="width: 100% !important">
               <span class="fw-semibold" >2(A) </span>&nbsp;&nbsp; Subject to sub-paragraph (B) below, all and any
              activities of the Company in the course of business, whether
              gratuitous or not, are undertaken subject to these conditions.
              <br />
              <br />
               <span class="fw-semibold" >(B)</span>&nbsp;&nbsp; If any legislation, to include regulations and
              directives, is compulsorily applicable to any business undertaken,
              these conditions shall, as regards such business, be read as
              subject to such legislation, and nothing in these conditions shall
              be construed as a surrender by the Company of any of its rights or
              immunities or as an increase of any of its responsibilities or
              liabilities under such legislation, and if any part of these
              conditions be repugnant to such legislation to any extent, such
              part shall as regards such business be overridden to that extent
              and no further.<br /><br />
               <span class="fw-semibold" >3</span>&nbsp;&nbsp; The Customer warrants that he is either the Owner,
              or the authorised agent of the Owner and, also, that he is
              accepting these conditions not only for himself, but also as agent
              for and on behalf of the Owner.
            </p>`,
  };

  ngOnInit():void{
    function toCapitalize(str: any): string {
      if (!str) return '';
      return str.charAt(0).toUpperCase() + str.slice(1);
  }
  this.title.setTitle(toCapitalize("Terms & Conditions")  + " - Intercargo Logistics");
   
  this.meta.removeTag('name="author"');
  this.meta.removeTag('property="og:title"');
  this.meta.removeTag('property="og:description"');
  // this.meta.removeTag('property="og:image"');
  this.meta.addTags([
   
    { name: 'author', content: 'Intercargo Logistics' },
    { property: 'og:title', content: 'Terms & Conditions' },
    {
      property: 'og:description', content: "Discover comprehensive guides and insights into the conditions of carriage for various transportation providers worldwide." },
    // { property: 'og:image', content: 'https://www.intercargo.com/assets/imgs/AirRight1.png' }
  ]); 

  }

  // ngAfterViewInit() {
  //   this.applyScrollBehavior();
  // }

  // @HostListener('window:resize', ['$event'])
  // onResize(event: Event) {
  //   this.applyScrollBehavior();
  // }

  // ngOnDestroy() {
  //   window.removeEventListener('resize', this.applyScrollBehavior);
  // }

  // applyScrollBehavior() {
  //   const screenWidth = window.innerWidth;

  //   if (screenWidth < 992) {
  //     const listItems = this.faqList.nativeElement.querySelectorAll('li');

  //     listItems.forEach((item: HTMLElement) => {
  //       item.addEventListener('click', this.scrollHandler.bind(this));
  //     });
  //   } else {
  //     const listItems = this.faqList.nativeElement.querySelectorAll('li');

  //     listItems.forEach((item: HTMLElement) => {
  //       item.removeEventListener('click', this.scrollHandler.bind(this));
  //     });
  //   }
  // }

  // private scrollHandler(event: Event) {
  //   const item = event.target as HTMLElement;
  //   const faqList = this.faqList.nativeElement;

  //   const activeItem = faqList.querySelector('.active');
  //   if (activeItem) {
  //     activeItem.classList.remove('active');
  //   }
  //   item.classList.add('active');
  //   // item.scrollIntoView({ inline: 'center', behavior: 'smooth' });
  // }

  getFAQs(faq: string) {
    this.activeItem = faq;
    switch (faq) {
      case 'definitions':
        this.policy = {
          heading: 'Definitions and Application',
          description: `<p class="mt-4 lh-lg" style="width: 100% !important">
                     <span class="fw-semibold" >1-</span> &nbsp;&nbsp;In these conditions the following words shall have
                    the following meanings:-
                    <br />
                    <br />
                  </p>
                  <table class="table table-bordered">
                     
                    <tbody>
                      <tr>
                           <td class="text-primary bg-primary" >“Company”</td>
                           <td class="text-primary bg-primary" >the BIFA member trading under these conditions</td>
                      </tr>
                      <tr>
                           <td class="text-primary bg-primary" >“Consignee”</td>
                           <td class="text-primary bg-primary" >the Person to whom the goods are consigned</td>
                      </tr>
                      <tr>
                           <td class="text-primary bg-primary" >“Customer”</td>
                           <td class="text-primary bg-primary" >
                          any Person at whose request or on whose behalf the Company
                          undertakes any business or provides advice, information or
                          services
                        </td>
                      </tr>
                      <tr>
                           <td class="text-primary bg-primary" >“Direct Customs Agent”</td>
                           <td class="text-primary bg-primary" >
                          the Company acting in the name of and on behalf of the
                          Customer and/or Owner with H.M. Revenue and Customs (“HMRC”)
                          as defined by the Taxation (Cross Border Trade) Act 2018,
                          Clause 21.1(a), or as amended
                        </td>
                      </tr>
                      <tr>
                           <td class="text-primary bg-primary" >“Goods”</td>
                           <td class="text-primary bg-primary" >
                          the cargo to which any business under these conditions
                          relates
                        </td>
                      </tr>
                      <tr>
                           <td class="text-primary bg-primary" >“LMAA”</td>
                           <td class="text-primary bg-primary" >the London Maritime Arbitrators Association</td>
                      </tr>
                      <tr>
                           <td class="text-primary bg-primary" >“SDR”</td>
                           <td class="text-primary bg-primary" >
                          are Special Drawing Rights as defined by the International
                          Monetary Fund
                        </td>
                      </tr>
                      <tr>
                           <td class="text-primary bg-primary" >“Transport Unit”</td>
                           <td class="text-primary bg-primary" >
                          packing case, pallets, container, trailer, tanker, or any
                          other device used whatsoever for and in connection with the
                          carriage of Goods by land, sea or air
                        </td>
                      </tr>
                      <tr>
                           <td class="text-primary bg-primary" >“Owner”</td>
                           <td class="text-primary bg-primary" >
                          the Owner of the Goods or Transport Unit and any other
                          Person who is or may become interested in them
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <p class="mt-4 lh-lg" style="width: 100% !important">
                     <span class="fw-semibold" >2(A)</span>&nbsp;&nbsp; Subject to sub-paragraph (B) below, all and any
                    activities of the Company in the course of business, whether
                    gratuitous or not, are undertaken subject to these conditions.
                    <br />
                    <br />
                     <span class="fw-semibold" >(B)</span>&nbsp;&nbsp; If any legislation, to include regulations and
                    directives, is compulsorily applicable to any business undertaken,
                    these conditions shall, as regards such business, be read as
                    subject to such legislation, and nothing in these conditions shall
                    be construed as a surrender by the Company of any of its rights or
                    immunities or as an increase of any of its responsibilities or
                    liabilities under such legislation, and if any part of these
                    conditions be repugnant to such legislation to any extent, such
                    part shall as regards such business be overridden to that extent
                    and no further.<br /><br />
                     <span class="fw-semibold" >3</span>&nbsp;&nbsp; The Customer warrants that he is either the Owner,
                    or the authorised agent of the Owner and, also, that he is
                    accepting these conditions not only for himself, but also as agent
                    for and on behalf of the Owner.
                  </p>`,
        };
        break;

      case 'company':
        this.policy = {
          heading: 'The Company',
          description: `<p class="mt-4 lh-lg" style="width: 100% !important">
  <span class="fw-semibold">8(A)&nbsp;&nbsp;</span> Subject to sub-clause (B) below, the Company:
  <br/>
  <br/>
  <span>(i)&nbsp;&nbsp;</span> has a general lien on all Goods and documents relating to Goods in its possession, custody or control for all sums due at any time to the Company from the Customer and/or Owner on any account whatsoever, whether relating to Goods belonging to, or services provided by or on behalf of the Company to the Customer or Owner. Storage charges shall continue to accrue on any Goods detained under lien;
  <br/>
  <br/>
  <span>(ii)&nbsp;&nbsp;</span> shall be entitled, on at least 21 days’ notice in writing to the Customer, to sell or dispose of or deal with such Goods or documents as agent for, and at the expense of, the Customer and apply the proceeds in or towards the payment of such sums;
  <br/>
  <br/>
  <span>(iii)&nbsp;&nbsp;</span> shall, upon accounting to the Customer for any balance remaining after payment of any sum due to the Company, and for the cost of sale and/or disposal and/or dealing, be discharged of any liability whatsoever in respect of the Goods or documents.
  <br/>
  <br/>
  <span class="fw-semibold">(B)&nbsp;&nbsp;</span> When the Goods are liable to perish or deteriorate, the Company's right to sell or dispose of or deal with the Goods shall arise immediately upon any sum becoming due to the Company, subject only to the Company taking reasonable steps to bring to the Customer's attention its intention to sell or dispose of the Goods before doing so.
  <br/>
  <br/>
  <span class="fw-semibold">9&nbsp;&nbsp;</span> The Company shall be entitled to retain and be paid all brokerages, commissions, allowances and other remunerations customarily retained by, or paid to, freight forwarders.
  <br/>
  <br/>
  <span class="fw-semibold">10(A)&nbsp;&nbsp;</span> Should the Customer, Consignee or Owner of the Goods fail to take delivery at the appointed time and place when and where the company is entitled to deliver, the Company shall be entitled to store the Goods, or any part thereof, at the sole risk of the Customer or Consignee or Owner, whereupon the Company’s liability in respect of the Goods, or that part thereof, stored as aforesaid, shall wholly cease. The Company’s liability, if any, in relation to such storage, shall be governed by these conditions. All costs incurred by the Company as a result of the failure to take delivery shall be deemed as freight earned, and such costs shall, upon demand, be paid by the Customer.
  <br/>
  <br/>
  <span class="fw-semibold">(B)&nbsp;&nbsp;</span> The Company shall be entitled at the expense of the Customer to dispose of or deal with (by sale or otherwise as may be reasonable in all the circumstances):-
  <br/>
  <br/>
  <span>(i)&nbsp;&nbsp;</span> after at least 21 days’ notice in writing to the Customer, or (where the Customer cannot be traced and reasonable efforts have been made to contact any parties who may reasonably be supposed by the Company to have any interest in the Goods) without notice, any Goods which have been held by the Company for 60 days and which cannot be delivered as instructed; and
  <br/>
  <br/>
  <span>(ii)&nbsp;&nbsp;</span> without prior notice, any Goods which have perished, deteriorated, or altered, or are in immediate prospect of doing so in a manner which has caused or may reasonably be expected to cause loss or damage to the Company, or third parties, or to contravene any applicable laws or regulations.
  <br/>
  <br/>
  <span class="fw-semibold">11(A)&nbsp;&nbsp;</span> No insurance will be effected except pursuant to and in accordance with clearly stated instructions given in writing by the Customer and accepted in writing by the Company, and all insurances effected by the Company are subject to the usual exceptions and conditions of the policies of the insurers or underwriters taking the risk. Unless otherwise agreed in writing, the Company shall not be under any obligation to effect a separate insurance on the Goods, but may declare it on any open or general policy held by the Company.
  <br/>
  <br/>
  <span class="fw-semibold">(B)&nbsp;&nbsp;</span> Insofar as the Company agrees to effect insurance, the Company acts solely as agent for the Customer, and the limits of liability under clause 26(A) of these conditions shall not apply to the Company’s obligations under clause 11.
  <br/>
  <br/>
  <span class="fw-semibold">12(A)&nbsp;&nbsp;</span> Except under special arrangements previously made in writing by an officer of the Company so authorised, or made pursuant to or under the terms of a printed document signed by the Company, any instructions relating to the delivery or release of the Goods in specified circumstances (such as, but not limited to, against payment or against surrender of a particular document) are accepted by the Company, where the Company has to engage third parties to effect compliance with the instructions, only as agents for the Customer.
  <br/>
  <br/>
  <span class="fw-semibold">(B)&nbsp;&nbsp;</span> Despite the acceptance by the Company of instructions from the Customer to collect freight, duties, charges, dues, or other expenses from the Consignee, or any other Person, on receipt of evidence of proper demand by the Company, and, in the absence of evidence of payment (for whatever reason) by such Consignee, or other Person, the Customer shall remain responsible for such freight, duties, charges, dues, or other expenses.
  <br/>
  <br/>
  <span class="fw-semibold">(C)&nbsp;&nbsp;</span> The Company shall not be under any liability in respect of such arrangements as are referred to under sub-clause (A) and (B) hereof save where such arrangements are made in writing, and in any event, the Company’s liability in respect of the performance of, or arranging the performance of, such instructions shall not exceed the limits set out in clause 26(A) (ii) of these conditions.
  <br/>
  <br/>
  <span class="fw-semibold">13&nbsp;&nbsp;</span> Advice and information, in whatever form it may be given, is provided by the Company for the Customer only. The Customer shall indemnify the Company against all loss and damage suffered as a consequence of passing such advice or information on to any third party.
  <br/>
  <br/>
  <span class="fw-semibold">14&nbsp;&nbsp;</span> Without prior agreement in writing by an officer of the Company so authorised, the Company will not accept or deal with Goods that require special handling regarding carriage, handling, or security whether owing to their thief attractive nature or otherwise including, but not limited to bullion, currency, securities, precious stones, jewellery, valuables, antiques, pictures, human remains, living creatures, plants. Should any Customer nevertheless deliver any such goods to the Company, or cause the Company to handle or deal with any such goods, otherwise than under such prior agreement, the Company shall have no liability whatsoever for or in connection with the goods, howsoever arising.
  <br/>
  <br/>
  <span class="fw-semibold">15&nbsp;&nbsp;</span> Except pursuant to instructions previously received in writing and accepted in writing by the Company, the Company will not accept or deal with Goods of a dangerous or damaging nature, nor with Goods likely to harbour or encourage vermin or other pests, nor with Goods liable to taint or affect other Goods. If such Goods are accepted pursuant to a special arrangement, but, thereafter, and in the opinion of the Company, constitute a risk to other goods, property, life or health, the Company shall, where reasonably practicable, contact the Customer in order to require him to remove or otherwise deal with the goods, but reserves the right, in any event, to do so at the expense of the Customer.
  <br/>
  <br/>
  <span class="fw-semibold">16&nbsp;&nbsp;</span> Where there is a choice of rates according to the extent or degree of the liability assumed by the Company and/or third parties, no declaration of value will be made and/or treated as having been made except under special arrangements previously made in writing by an officer of the Company so authorised as referred to in clause 26(D).
</p>
`,
        };
        break;

      case 'customer':
        this.policy = {
          heading: 'The Customer',
          description: ` <p class="mt-4 lh-lg" style="width: 100% !important">
  <span class="fw-semibold">17&nbsp;&nbsp;</span> The Customer warrants:
  <br/>
  <br/>
  <span class="fw-semibold">(A)&nbsp;&nbsp;</span>
  <br/>
  <br/>
  <span class="fw-semibold">(i)&nbsp;&nbsp;</span> that the following (furnished by on or behalf of the Customer) are full and accurate: the description and particulars of any Goods; any information furnished (including but not limited to, the nature, gross weight, gross mass (including the verified actual gross mass of any container packed with packages and cargo items), and measurements of any Goods); and the description and particulars of any services required by or on behalf of the Customer are full and accurate, and
  <br/>
  <br/>
  <span class="fw-semibold">(ii)&nbsp;&nbsp;</span> that any Transport Unit and/or equipment supplied by the Customer in relation to the performance of any requested service is fit for purpose;
  <br/>
  <br/>
  <span class="fw-semibold">(B)&nbsp;&nbsp;</span> that all Goods have been properly and sufficiently prepared, packed, stowed, labelled and/or marked, and that the preparation, packing, stowage, labelling and marking are appropriate to any operations or transactions affecting the Goods and the characteristics of the Goods.
  <br/>
  <br/>
  <span class="fw-semibold">(C)&nbsp;&nbsp;</span> that where the Company receives the Goods from the Customer already stowed in or on a Transport Unit, the Transport Unit is in good condition, and is suitable for the carriage to the intended destination of the Goods loaded therein, or thereon;
  <br/>
  <br/>
  <span class="fw-semibold">(D)&nbsp;&nbsp;</span> that where the Company provides the Transport Unit, on loading by the Customer, the Transport Unit is in good condition, and is suitable for the carriage to the intended destination of the Goods loaded therein, or thereon.
  <br/>
  <br/>
  <span class="fw-semibold">18&nbsp;&nbsp;</span> Without prejudice to any rights under clause 15, where the Customer delivers to the Company, or causes the Company to deal with or handle Goods of a dangerous or damaging nature, or Goods likely to harbour or encourage vermin or other pests, or Goods liable to taint or affect other goods, whether declared to the Company or not, he shall be liable for all loss or damage arising in connection with such Goods, and shall indemnify the Company against all penalties, claims, damages, costs and expenses whatsoever arising in connection therewith, and the Goods may be dealt with in such manner as the Company, or any other person in whose custody they may be at any relevant time, shall think fit.
  <br/>
  <br/>
  <span class="fw-semibold">19&nbsp;&nbsp;</span> The Customer undertakes that no claim shall be made against any director, servant, or employee of the Company which imposes, or attempts to impose, upon them any liability in connection with any services which are the subject of these conditions, and, if any such claim should nevertheless be made, to indemnify the Company against all consequences thereof.
  <br/>
  <br/>
  <span class="fw-semibold">20&nbsp;&nbsp;</span> The Customer shall save harmless and keep the Company indemnified from and against
  <br/>
  <br/>
  <span class="fw-semibold">(A)&nbsp;&nbsp;</span> all liability, loss, damage, costs and expenses whatsoever (including, without prejudice to the generality of the foregoing, all duties, taxes, imposts, levies, deposits and outlays of whatsoever nature levied by any authority in relation to the Goods) arising out of the Company acting in accordance with the Customer's instructions, or arising from any breach by the Customer of any warranty contained in these conditions, or from the negligence of the Customer;
  <br/>
  <br/>
  <span class="fw-semibold">(B)&nbsp;&nbsp;</span> without derogation from sub-clause (A) above, any liability assumed, or incurred by the Company when, by reason of carrying out the Customer's instructions, the Company has become liable to any other party;
  <br/>
  <br/>
  <span class="fw-semibold">(C)&nbsp;&nbsp;</span> all claims, costs and demands whatsoever and by whomsoever made or preferred, in excess of the liability of the Company under the terms of these conditions, regardless of whether such claims, costs, and/or demands arise from, or in connection with, the breach of contract, negligence or breach of duty of the Company, its servants, sub-contractors or agents;
  <br/>
  <br/>
  <span class="fw-semibold">(D)&nbsp;&nbsp;</span> any claims of a general average nature which may be made on the Company.
  <br/>
  <br/>
  <span class="fw-semibold">21(A)&nbsp;&nbsp;</span> The punctual receipt in full of sums falling due from the Customer to the Company is critical to the operation of the Company’s business and its performance of its obligations to the Customer. Accordingly the Customer shall pay to the Company in cash, or as otherwise agreed, all sums when due, immediately and without reduction or deferment on account of any claim, counterclaim or set-off. Time is of the essence of payment of all and any sums payable by the Customer to the Company.
  <br/>
  <br/>
  <span class="fw-semibold">(B)&nbsp;&nbsp;</span> In the event of any failure by the Customer to make full and punctual payment of any sum payable to the Company (in accordance with clause 21(A) above):
  <br/>
  <br/>
  <span class="fw-semibold">(i)&nbsp;&nbsp;</span> Any and all other sums properly earned by and/or otherwise due to the Company (but which, but for this clause 21(B), would otherwise not yet be payable by the Customer, whether by virtue of an agreed credit period or otherwise) shall become immediately payable in full; and
  <br/>
  <br/>
  <span class="fw-semibold">(ii)&nbsp;&nbsp;</span> Any sum thereby becoming immediately payable shall be paid to the Company in cash, or as otherwise agreed, and without reduction or deferment on account of any claim, counterclaim or set-off.
  <br/>
  <br/>
  <span class="fw-semibold">(C)&nbsp;&nbsp;</span> No omission to seek compensation for breach of 21(A) and (B) above by the Company shall constitute a waiver or release to the Customer from any liability under 21(A) and (B) above during the application of these terms unless agreed in writing by authorised officers of the Company and Customer.
  <br/>
  <br/>
  <span class="fw-semibold">(D)&nbsp;&nbsp;</span> The Late Payment of Commercial Debts (Interest) Act 1998, as amended, shall apply to all sums due from the Customer.
  <br/>
  <br/>
  <span class="fw-semibold">22&nbsp;&nbsp;</span> Where liability arises in respect of claims of a general average nature in connection with the Goods, the Customer shall promptly provide security to the Company, or to any other party designated by the Company, in a form acceptable to the Company.
</p>
`,
        };
        break;

      case 'liability':
        this.policy = {
          heading: 'Liability and Limitations',
          description: ` <p class="mt-4 lh-lg" style="width: 100% !important">
  <span class="fw-semibold">23&nbsp;&nbsp;</span> The Company shall perform its duties with a reasonable degree of care, diligence, skill and judgment.
  <br/>
  <br/>
  <span class="fw-semibold">24&nbsp;&nbsp;</span> The Company shall be relieved of liability for any loss or damage if, and to the extent that, such loss or damage is caused by:-
  <br/>
  <br/>
  <span class="fw-semibold">(A)&nbsp;&nbsp;</span> strike, lock-out, stoppage or restraint of labour, the consequences of which the Company is unable to avoid by the exercise of reasonable diligence; or
  <br/>
  <br/>
  <span class="fw-semibold">(B)&nbsp;&nbsp;</span> any cause or event which the Company is unable to avoid, and the consequences of which the company is unable to prevent by the exercise of reasonable diligence.
  <br/>
  <br/>
  <span class="fw-semibold">25&nbsp;&nbsp;</span> Except under special arrangements previously made in writing by an officer of the Company so authorised, the Company accepts no responsibility with regard to any failure to adhere to agreed departure or arrival dates of Goods.
  <br/>
  <br/>
  <span class="fw-semibold">26(A)&nbsp;&nbsp;</span> Subject to clause 2(B) and 11(B) above and sub-clause (D) below, the Company’s liability howsoever arising and, notwithstanding that the cause of loss or damage be unexplained, shall not exceed:
  <br/>
  <br/>
  <span class="fw-semibold">(i)&nbsp;&nbsp;</span> in the case of claims for loss or damage to Goods:
  <br/>
  <br/>
  <span class="fw-semibold">(a)&nbsp;&nbsp;</span> the value of any loss or damage; or 
  <br/>
  <br/>
  <span class="fw-semibold">(b)&nbsp;&nbsp;</span> a sum at the rate of 2 SDR per kilo of the gross weight of any Goods lost or damaged whichever shall be the lesser
  <br/>
  <br/>
  <span class="fw-semibold">(ii)&nbsp;&nbsp;</span> subject to (iii) below, in the case of all other claims:
  <br/>
  <br/>
  <span class="fw-semibold">(a)&nbsp;&nbsp;</span> the value of the subject Goods of the relevant transaction between the Company and its Customer; or 
  <br/>
  <br/>
  <span class="fw-semibold">(b)&nbsp;&nbsp;</span> where the weight can be defined, a sum calculated at the rate of 2 SDR per kilo of the gross weight of the subject Goods of the said transaction; or 
  <br/>
  <br/>
  <span class="fw-semibold">(c)&nbsp;&nbsp;</span> 75,000 SDR in respect of any one transaction, whichever shall be the lesser.
  <br/>
  <br/>
  <span class="fw-semibold">(iii)&nbsp;&nbsp;</span> in the case of an error and/or omission, or a series of errors and/or omissions which are repetitions of or represent the continuation of an original error and/or omission:
  <br/>
  <br/>
  <span class="fw-semibold">(a)&nbsp;&nbsp;</span> the loss incurred; or
  <br/>
  <br/>
  <span class="fw-semibold">(b)&nbsp;&nbsp;</span> 75,000 SDR in the aggregate of any one trading year commencing from the time of the making of the original error and/or omission, whichever shall be the lesser.
  <br/>
  <br/>
  For the purposes of clause 26(A), the value of the Goods shall be their value when they were, or should have been, shipped. The value of SDR shall be calculated as at the date when the claim is received by the Company in writing.
  <br/>
  <br/>
  <span class="fw-semibold">(B)&nbsp;&nbsp;</span> Subject to clause 2(B) above and sub-clause (D) below, the Company’s liability for loss or damage as a result of failure to deliver, or arrange delivery of goods, in a reasonable time, or (where there is a special arrangement under Clause 25) to adhere to agreed departure or arrival dates, shall not in any circumstances whatever exceed a sum equal to twice the amount of the Company’s charges in respect of the relevant contract.
  <br/>
  <br/>
  <span class="fw-semibold">(C)&nbsp;&nbsp;</span> Save in respect of such loss or damage as is referred to at sub-clause (B), and subject to clause 2(B) above and sub-clause (D) below, the Company shall not in any circumstances whatsoever be liable for indirect or consequential loss such as (but not limited to) loss of profit, loss of market, or the consequences of delay or deviation, however caused.
  <br/>
  <br/>
  <span class="fw-semibold">(D)&nbsp;&nbsp;</span> On clearly stated instructions in writing declaring the commodity and its value, received from the Customer and accepted by the Company, the Company may accept liability in excess of the limits set out in sub-clauses (A) to (C) above upon the Customer agreeing to pay the Company’s additional charges for accepting such increased liability. Details of the Company’s additional charges will be provided upon request.
  <br/>
  <br/>
  <span class="fw-semibold">27(A)&nbsp;&nbsp;</span> Any claim by the Customer against the Company arising in respect of any service provided for the Customer, or which the Company has undertaken to provide, shall be made in writing and notified to the Company within 14 days of the date upon which the Customer became, or ought reasonably to have become, aware of any event or occurrence alleged to give rise to such claim, and any claim not made and notified as aforesaid shall be deemed to be waived and absolutely barred, except where the Customer can show that it was impossible for him to comply with this time limit, and that he has made the claim as soon as it was reasonably possible for him to do so.
  <br/>
  <br/>
  <span class="fw-semibold">(B)&nbsp;&nbsp;</span> Notwithstanding the provisions of sub-paragraph (A) above, the Company shall in any event be discharged of all liability whatsoever and howsoever arising in respect of any service provided for the Customer, or which the Company has undertaken to provide, unless suit be brought and written notice thereof given to the Company within nine months from the date of the event or occurrence alleged to give rise to a cause of action against the Company.
</p>

`,
        };
        break;

      case 'law':
        this.policy = {
          heading: 'Jurisdiction and Law',
          description: ` <p class="mt-4 lh-lg" style="width: 100% !important">
  <span class="fw-semibold">28&nbsp;&nbsp;</span>
  <span class="fw-semibold">(A)&nbsp;&nbsp;</span> These conditions and any act or contract to which they apply shall be governed by English law.
  <br/>
  <br/>
  <span class="fw-semibold">(B)&nbsp;&nbsp;</span> Any dispute arising out of any act or contract to which these Conditions apply shall, save as provided in (C) below, be subject to the exclusive jurisdiction of the English courts.
  <br/>
  <br/>
  <span class="fw-semibold">(C)&nbsp;&nbsp;</span> Notwithstanding (B) above, the Company is entitled to require any dispute to be determined by arbitration.
  <br/>
  <br/>
  <span class="fw-semibold">(D)&nbsp;&nbsp;</span> The Company may exercise its rights under (C) above either by itself commencing arbitration in respect of a dispute or by giving written notice to the Customer requiring a dispute to be determined by arbitration.
  <br/>
  <br/>
  <span class="fw-semibold">(E)&nbsp;&nbsp;</span> In the event that the Company exercises its rights under (C) above, the corresponding arbitration shall be conducted as follows:
  <br/>
  <br/>
  <span class="fw-semibold">(i)&nbsp;&nbsp;</span> Where the amount claimed by the claimant is less than £400,000, excluding interest, (or such other sum as the Company and Customer may agree, and subject to (iii) below), the reference shall be to a tribunal of three arbitrators and the arbitration shall be conducted in accordance with the LMAA Intermediate Claims Procedure applicable at the date of the commencement of the arbitration proceedings;
  <br/>
  <br/>
  <span class="fw-semibold">(ii)&nbsp;&nbsp;</span> Where the amount claimed by the claimant is less than £100,000, excluding interest, (or such other sum as the Company and Customer may agree, and subject to (iii) below), the reference shall be to a sole arbitrator and the arbitration shall be conducted in accordance with the LMAA Small Claims Procedure applicable at the date of the commencement of the arbitration proceedings;
  <br/>
  <br/>
  <span class="fw-semibold">(iii)&nbsp;&nbsp;</span> In any case where neither of the LMAA Procedures referred to in (i) and/or (ii) above applies, the reference shall be to three arbitrators in accordance with the LMAA Terms applicable at the date of the commencement of the arbitration proceedings.
</p>

`,
        };
        break;

      default:
    }
  }

  checkInputValue(event: FocusEvent) {
    const inputElement = event.target as HTMLInputElement;
    if (!inputElement.value.trim()) {
      this.isDisabled = true;
    }
  }

  rightImageData = {
    heading: 'Conditions Of Carriage',
    description: `Discover comprehensive guides and insights into the conditions of carriage for various transportation providers worldwide.`,
    img: '../../../assets/imgs/TermsConditions.png',
    btnTxt1: '',
    btnLink1: '',
    btnTxt2: ' ',
    btnLink2: '',
  };
}
