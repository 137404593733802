import { Component, ElementRef, Inject, PLATFORM_ID, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { OrganizationService } from '../../services/organization.service';
import { RightLeftComponentData } from '../../shared/models/right-left-component-data';
import { CityService } from '../../services/city.service';
import { isPlatformBrowser } from '@angular/common';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-globe',
  templateUrl: './globe.component.html',
  styleUrl: './globe.component.css',
})
export class GlobeComponent {
  @ViewChild('mapImg') mapImg: ElementRef | undefined;
  @ViewChild('overlayCard') overlayCard: ElementRef | undefined;

  imgPath = '../../assets/imgs/Globe/World.png';
  showClass: boolean = false;
  isExpanded: boolean = false;
  cardCountryName: string = '';
  activeItem: string = '';
  currentCountry: string = '';
  loader: boolean = true;

  constructor(private http: HttpClient, private organizationService: OrganizationService, private CityService: CityService, @Inject(PLATFORM_ID) private platformId: Object, private meta: Meta, private title: Title,) {


  }


  leftImageInfo: RightLeftComponentData[] = []
  ngOnInit() {
    this.loader = true;

    this.fetchAgents();
    this.meta.updateTag({ name: 'description', content: "Intercargo's Wide Global Network of Agents" });
    this.title.setTitle("Global Network - Intercargo Logistics");

    this.meta.removeTag('property="og:title"');
    this.meta.removeTag('property="og:description"');
    this.meta.addTags([
      { property: 'og:description', content: "Intercargo's Global Network of Agents" },
      { property: 'og:title', content: 'Global Agents' },


    ]);


    if (this.overlayCard) { this.overlayCard.nativeElement.style.display = 'none' };
    this.leftImageInfo.push({
      heading: `Put Us to Work as Your 
Global Logistics Platform`,
      description: `Schedule a call to learn how our platform delivers end-to-end results.`,
      img: '../../../assets/imgs/Gif/Contact.gif',
      btnTxt: 'Talk to an Expert',
      btnLink: '/support',
      class: 'text-center'
    })

  }

  // ngAfterViewInit() {
  //   this.loader = true;
  //   this.filterCountries();
  // }
  agents: any[] = [];
  organizedAgents: any = {};




  async fetchAgents() {


    await this.organizationService.fetchAgents().subscribe(
      (data: any[]) => {
        this.agents = data;
        this.organizeAgents();
        if (this.currentCountry) {
          this.mapAgents(this.currentCountry);
        }


      },
      error => {
        console.error('Error fetching agents:', error);
        this.loader = false;
      }
    );

  }

  organizeAgents() {
    this.agents.forEach(agentCategory => {
      Object.keys(agentCategory).forEach(type => {
        agentCategory[type].forEach((agent: { country: { code: any; }; }) => {
          const countryCode = agent.country.code;

          // Check if the countryCode already exists in organizedAgents, if not, initialize it
          if (!this.organizedAgents[countryCode]) {
            this.organizedAgents[countryCode] = {
              air: [],
              sea: [],
              road: [],
              rail: []
            };
          }

          // Push the agent into the corresponding type array
          this.organizedAgents[countryCode][type].push(agent);
        });
      });
    });
    this.filterCountries();
  }


  countries = this.CityService.getCountries();
  // agents: Agent[] = [
  //   {
  //     companyName: 'Company 1',
  //     address: 'Address 1',
  //     phone: '1234567890',
  //     email: 'dummy@email.com',
  //     tel: '876-929-7685',
  //   },
  //   {
  //     companyName: 'Company 2',
  //     address: 'Address 2',
  //     phone: '1234567890',
  //     email: 'dummy@email.com',
  //     tel: '876-929-7685',
  //   },
  //   {
  //     companyName: 'Company 3',
  //     address: 'Address 3',
  //     phone: '1234567890',
  //     email: 'dummy@email.com',
  //     tel: '876-929-7685',
  //   },
  //   {
  //     companyName: 'Company 4',
  //     address: 'Address 4',
  //     phone: '1234567890',
  //     email: 'dummy@email.com',
  //     tel: '876-929-7685',
  //   },
  // ];

  AsianCountries: any = [
    { name: 'Sri Lanka', language: 'English' },
    { name: 'Malaysia', language: 'English' },
    { name: 'South Korea', language: 'English' },
    { name: 'Oman', language: 'English' },
    { name: 'Iran', language: 'English' },
    { name: 'Thailand', language: 'English' },
    { name: 'India', language: 'English' },
    { name: 'Pakistan', language: 'English' },
    { name: 'Vietnam', language: 'English' },
    { name: 'China', language: 'English' },
    { name: 'Bangladesh', language: 'English' },
    { name: 'Hong Kong', language: 'English' },
    { name: 'Singapore', language: 'English' },
    { name: 'Qatar', language: 'English' },
    { name: 'Jordan', language: 'English' },
    { name: 'Turkey', language: 'English' },
    { name: 'Kuwait', language: 'English' }
  ];

  EuropeanCountries: any = [
    { name: 'Norway', language: 'English' },
    { name: 'Ukraine', language: 'English' },
    { name: 'United Kingdom', language: 'English' },
    { name: 'Greece', language: 'English' },
    { name: 'Switzerland', language: 'English' },
    { name: 'Cyprus', language: 'English' },
    { name: 'France', language: 'English' },
    { name: 'Germany', language: 'English' },
    { name: 'Netherlands', language: 'English' },
    { name: 'Turkey', language: 'English' },
    { name: 'Poland', language: 'English' },
    { name: 'Ireland', language: 'English' }
  ];

  CentralAndSouthAmericanCountries: any = [
    { name: 'Costa Rica', language: 'English' },
    { name: 'Brazil', language: 'English' },
    { name: 'Argentina', language: 'English' },
    { name: 'Panama', language: 'English' },
    { name: 'Chile', language: 'English' },
    { name: 'Colombia', language: 'English' }
  ];


  AfricanCountries: any = [
    { name: 'Ghana', language: 'English' },
    { name: 'Kenya', language: 'English' },
    { name: 'South Africa', language: 'English' },
    { name: 'Egypt', language: 'English' },
    { name: 'Nigeria', language: 'English' },
    { name: 'Zimbabwe', language: 'English' },
    { name: 'Libya', language: 'English' },
    { name: 'Tanzania', language: 'English' }
  ];


  MiddleEastCountries: any = [
    { name: 'Yemen', language: 'English' },
    { name: 'Syrian Arab Republic', language: 'English' },
    { name: 'Palestine', language: 'English' },
    { name: 'Qatar', language: 'English' },
    { name: 'Oman', language: 'English' },
    { name: 'Kuwait', language: 'English' },
    { name: 'Israel', language: 'English' },
    { name: 'Iraq', language: 'English' },
    { name: 'United Arab Emirates', language: 'English' },
    { name: 'Saudi Arabia', language: 'English' },
    { name: 'Jordan', language: 'English' },
    { name: 'Lebanon', language: 'English' },
  ];

  NorthAmericanCountries: any = [
    { name: 'United States', language: 'English' },
    { name: 'Barbados', language: 'English' },
    { name: 'Canada', language: 'English' },
    { name: 'Costa Rica', language: 'English' },
    { name: 'Panama', language: 'English' },
    { name: 'Brazil', language: 'English' },
    { name: 'Argentina', language: 'English' },
    { name: 'Chile', language: 'English' },
    { name: 'Colombia', language: 'English' }
  ]

  OceaniaCountries: any = [
    { name: 'Australia', language: 'English' },

    { name: 'New Zealand', language: 'English' }

  ];
  continents: Continent[] = [
    { name: 'Asia', imagePath: '../../assets/imgs/Globe/Asia.png' },
    { name: 'europe', imagePath: '../../assets/imgs/Globe/Europe.png' },
    { name: 'NorthAmerica', imagePath: '../../assets/imgs/Globe/North America.png' },
    { name: 'southAmerica', imagePath: '../../assets/imgs/Globe/South America.png' },
    // { name: 'South America', imagePath: '../../assets/imgs/world.svg' },
    { name: 'Oceania', imagePath: '../../assets/imgs/Globe/Australia.png' },
    { name: 'africa', imagePath: '../../assets/imgs/Globe/Africa.png' },
    // { name: 'MiddleEast', imagePath: '../../assets/imgs/world.svg' },

    //Asian countries
    { name: 'Sri Lanka', imagePath: '../../assets/svgs/lk.svg' },
    { name: 'Malaysia', imagePath: '../../assets/svgs/my.svg' },
    { name: 'South Korea', imagePath: '../../assets/svgs/kr.svg' },
    { name: 'Oman', imagePath: '../../assets/svgs/om.svg' },
    { name: 'Iran', imagePath: '../../assets/svgs/ir.svg' },
    { name: 'Thailand', imagePath: '../../assets/svgs/th.svg' },
    { name: 'India', imagePath: '../../assets/svgs/in.svg' },
    { name: 'Pakistan', imagePath: '../../assets/svgs/pk.svg' },
    { name: 'Vietnam', imagePath: '../../assets/svgs/vn.svg' },
    { name: 'China', imagePath: '../../assets/svgs/cn.svg' },
    { name: 'Bangladesh', imagePath: '../../assets/svgs/bd.svg' },
    { name: 'Hong Kong', imagePath: '../../assets/svgs/hk.svg' },
    { name: 'Singapore', imagePath: '../../assets/svgs/sg.svg' },
    { name: 'Qatar', imagePath: '../../assets/svgs/qa.svg' },
    { name: 'Jordan', imagePath: '../../assets/svgs/jo.svg' },
    { name: 'Turkey', imagePath: '../../assets/svgs/tr.svg' },
    { name: 'Kuwait', imagePath: '../../assets/svgs/kw.svg' },



    //European countries

    { name: 'Norway', imagePath: '../../assets/svgs/no.svg' },
    { name: 'Ukraine', imagePath: '../../assets/svgs/ua.svg' },
    { name: 'United Kingdom', imagePath: '../../assets/svgs/gb.svg' },
    { name: 'Greece', imagePath: '../../assets/svgs/gr.svg' },
    { name: 'Switzerland', imagePath: '../../assets/svgs/ch.svg' },
    { name: 'Cyprus', imagePath: '../../assets/svgs/cy.svg' },
    { name: 'France', imagePath: '../../assets/svgs/fr.svg' },
    { name: 'Germany', imagePath: '../../assets/svgs/de.svg' },
    { name: 'Netherlands', imagePath: '../../assets/svgs/nl.svg' },
    { name: 'Turkey', imagePath: '../../assets/svgs/tr.svg' },
    { name: 'Poland', imagePath: '../../assets/svgs/pl.svg' },
    { name: 'Ireland', imagePath: '../../assets/svgs/ie.svg' },


    // Central and South America

    { name: 'Costa Rica', imagePath: '../../assets/svgs/cr.svg' },
    { name: 'Brazil', imagePath: '../../assets/svgs/br.svg' },
    { name: 'Argentina', imagePath: '../../assets/svgs/ar.svg' },
    { name: 'Panama', imagePath: '../../assets/svgs/pa.svg' },
    { name: 'Chile', imagePath: '../../assets/svgs/cl.svg' },
    { name: 'Colombia', imagePath: '../../assets/svgs/co.svg' },


    // African countries

    { name: 'Ghana', imagePath: '../../assets/svgs/gh.svg' },
    { name: 'Kenya', imagePath: '../../assets/svgs/ke.svg' },
    { name: 'South Africa', imagePath: '../../assets/svgs/za.svg' },
    { name: 'Egypt', imagePath: '../../assets/svgs/eg.svg' },
    { name: 'Nigeria', imagePath: '../../assets/svgs/ng.svg' },
    { name: 'Zimbabwe', imagePath: '../../assets/svgs/zw.svg' },
    { name: 'Libya', imagePath: '../../assets/svgs/ly.svg' },
    { name: 'Tanzania', imagePath: '../../assets/svgs/tz.svg' },


    //North America
    { name: 'United States', imagePath: '../../assets/svgs/us.svg' },
    { name: 'Barbados', imagePath: '../../assets/svgs/bb.svg' },
    { name: 'Canada', imagePath: '../../assets/svgs/ca.svg' },
    { name: 'Costa Rica', imagePath: '../../assets/svgs/cr.svg' },
    { name: 'Panama', imagePath: '../../assets/svgs/pa.svg' },
    { name: 'Brazil', imagePath: '../../assets/svgs/br.svg' },
    { name: 'Argentina', imagePath: '../../assets/svgs/ar.svg' },
    { name: 'Chile', imagePath: '../../assets/svgs/cl.svg' },
    { name: 'Colombia', imagePath: '../../assets/svgs/co.svg' },


    //Oceania countries
    { name: 'Australia', imagePath: '../../assets/svgs/au.svg' },
    { name: 'New Zealand', imagePath: '../../assets/svgs/nz.svg' }

  ];

  checkIfCountry(name: string): boolean {
    const continentNames = [
      'Asia',
      'europe',
      'southAmerica',
      'NorthAmerica',
      'South America',
      'Oceania',
      'africa',
      'MiddleEast',
    ];
    return continentNames.includes(name);
  }

  toggleCollapse(cardId: string, continentName: string, heading: string) {
    if (isPlatformBrowser(this.platformId)) {
      window.scrollTo(0, 400);
      const collapseElement = document.getElementById(cardId);

      // Close all expanded cards
      const allCards = document.querySelectorAll('.card');
      allCards.forEach((card) => {
        const cardCollapse = card.querySelector('.collapse.show');
        if (cardCollapse && cardCollapse !== collapseElement) {
          cardCollapse.classList.remove('show');
          const cardHeading = card.querySelector('.card-header');
          if (cardHeading) {
            const chevronUpIcon = cardHeading.querySelector('.bi.bi-chevron-up');
            if (chevronUpIcon) {
              chevronUpIcon.classList.replace('bi-chevron-up', 'bi-chevron-down');
            }
            cardHeading.classList.remove('expanded');
          }
        }
      });

      // Toggle the current card
      if (collapseElement) {
        collapseElement.classList.toggle('show');
        this.changeImage(continentName);
      }

      const cardHeader = document.getElementById(heading);
      if (cardHeader) {
        const chevronIcon = cardHeader.querySelector('.bi.bi-chevron-down');
        if (chevronIcon) {
          chevronIcon.classList.replace('bi-chevron-down', 'bi-chevron-up');
        } else {
          const chevronUpIcon = cardHeader.querySelector('.bi.bi-chevron-up');
          if (chevronUpIcon) {
            chevronUpIcon.classList.replace('bi-chevron-up', 'bi-chevron-down');
          }
        }
        cardHeader.classList.toggle('expanded');
      }
    }
  }

  changeImage(continentName: string) {
    this.activeItem = continentName;
    if (!this.checkIfCountry(continentName)) {
      if (this.mapImg && this.overlayCard) {
        this.currentCountry = continentName;
        this.mapAgents(this.currentCountry)
        this.mapImg.nativeElement.style.width = '50%';
        this.cardCountryName = continentName;
        this.overlayCard.nativeElement.style.display = 'flex';
      }
    } else {
      if (this.mapImg && this.overlayCard) {
        this.mapImg.nativeElement.style.width = '70%';
        this.overlayCard.nativeElement.style.display = 'none';
      }
    }
    this.imgPath =
      this.continents.find(
        (continent) =>
          continent.name.toLowerCase() === continentName.toLowerCase()
      )?.imagePath || '';
  }

  air: any[] = []
  sea: any[] = []
  road: any[] = []
  rail: any[] = []


  countryAgents: any;
  mapAgents(country: any) {
    const countryCode = this.countries.find((c) => c.name === country);
    if (countryCode?.code) {
      const organizedAgentKeys = Object.keys(this.organizedAgents);
      if (organizedAgentKeys.includes(countryCode.code)) {
        this.countryAgents = this.organizedAgents[countryCode.code];

        let agentKeys = Object.keys(this.countryAgents);

        if (agentKeys.includes('air')) {
          this.air = this.countryAgents.air;
        }

        if (agentKeys.includes('sea')) {
          this.sea = this.countryAgents.sea;
        }

        if (agentKeys.includes('road')) {
          this.road = this.countryAgents.road;
        }

        if (agentKeys.includes('rail')) {
          this.rail = this.countryAgents.rail;
        }

        // You can log or further process these arrays as needed
        // this.loader = false;
      }


    } else {
      // this.removeCountryFromRegion(country);
    }
    // if (countryCode) {
    //   this.countryAgents = this.agents.filter(
    //     (agent) => {
    //       agent.country.code === countryCode.code
    //     }
    //   );

    // }

  }

  removeCountryFromRegion(countryName: string) {
    const regions = [
      this.AsianCountries,
      this.EuropeanCountries,
      this.CentralAndSouthAmericanCountries,
      this.AfricanCountries,
      this.MiddleEastCountries,
      this.NorthAmericanCountries,
      this.OceaniaCountries
    ];

    regions.forEach(region => {
      const index = region.findIndex((country: { name: string; }) => country.name === countryName);
      if (index !== -1) {
        region.splice(index, 1);
      }
    });
  }

  filterCountries() {

    const agentCountryCodes = Object.keys(this.organizedAgents);
    const filterContinentCountries = (continentArray: any[]) => {
      return continentArray.filter(country => {
        const countryCode = this.countries.find(c => c.name === country.name)?.code;
        return countryCode && agentCountryCodes.includes(countryCode);
      });
    };

    this.AsianCountries = filterContinentCountries(this.AsianCountries);
    this.EuropeanCountries = filterContinentCountries(this.EuropeanCountries);
    this.CentralAndSouthAmericanCountries = filterContinentCountries(this.CentralAndSouthAmericanCountries);
    this.AfricanCountries = filterContinentCountries(this.AfricanCountries);
    this.MiddleEastCountries = filterContinentCountries(this.MiddleEastCountries);
    this.NorthAmericanCountries = filterContinentCountries(this.NorthAmericanCountries);
    this.OceaniaCountries = filterContinentCountries(this.OceaniaCountries);


    this.loader = false;
    // Add filtering for other continent arrays as needed
  }
}

// interface Agent {
//   companyName: string;
//   address: string;
//   phone: string;
//   email: string;
//   tel: string;
// }

interface Continent {
  name: string;
  imagePath: string;
}
// interface Agent {
//   name: string;
//   contact: string;
//   phone: string;
//   email: string;
//   city: string;
//   country: {
//     code: string;
//     pk: string;
//     tableName: string;
//   };
// }