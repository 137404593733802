<section class="container-fluid bg-light">
    <div class="row align-items-center justify-content-center p-4">
      <div
        class="col-lg-4 col-12 order-lg-1 order-2 align-items-lg-start text-lg-start text-md-start text-start my-5"
      >
        <h1
          [innerHTML]="rightImageData.heading"
          class="text-lg-start text-md-start fs-1 fw-semibold"
          style="margin-bottom: 5% !important"
        ></h1>
        <p
          [innerHTML]="rightImageData.description"
          class="text-lg-start text-md-start"
        ></p>
      </div>
  
      <div class="col-lg-2 d-none d-lg-block"></div>
  
      <div class="col-lg-6 col-md-12 order-lg-3 order-1">
        <figure class="image-container text-center">
          <img
            [src]="rightImageData.img"
            alt="Logistics solutions"
            class="img-fluid"
          />
        </figure>
      </div>
    </div>
  </section>
  
  <section class="container-fluid py-5">
    <div class="row">
      <div
        class="col-lg-3 col-md-12 order-lg-1 order-2 d-flex flex-column align-items-start text-start align-items-lg-start"
      >
        <i
          class="bi bi-arrow-left-short fs-2"
          style="cursor: pointer; display: none"
          #backIcon
        ></i>
        <h1 class="text-start fs-2 fw-medium" style="padding-left: 5% !important">
          Privacy Polices
        </h1>
  
        <div class="d-flex row flex-lg-column my-5 align-items-md-start w-100">
          <div
            class="input-group mb-3 d-flex align-items-center text-lg-start text-md-start"
          >
            <ul class="link-list w-100 p-0" #faqList>
              <li
                (click)="getFAQs('general')"
                [class.active]="activeItem === 'general'"
              >
                General information
              </li>
              <li
                (click)="getFAQs('sensitive')"
                [class.active]="activeItem === 'sensitive'"
              >
                Sensitive Personal Data
              </li>
              <li
                (click)="getFAQs('transfer')"
                [class.active]="activeItem === 'transfer'"
              >
                Transfer of Data
              </li>
              <li
                (click)="getFAQs('data')"
                [class.active]="activeItem === 'data'"
              >
                Data Collection
              </li>
              <li
                (click)="getFAQs('security')"
                [class.active]="activeItem === 'security'"
              >
                Security Check
              </li>
              <li
                (click)="getFAQs('cookie')"
                [class.active]="activeItem === 'cookie'"
              >
                Cookie Standard
              </li>
            </ul>
          </div>
        </div>
      </div>
  
      <div class="col-lg-2 d-none d-lg-block"></div>
  
      <div class="col-lg-6 col-md-12 order-lg-3 order-2">
        <div>
          <div class="faq-container">
            <h1
              class="text-primary text-start text-md-start fw-medium fs-2"
              [innerHTML]="policy.heading"
            ></h1>
  
            <div class="w-100 text-start">
              <p class="mt-4"
                [innerHTML]="policy.description"
                style="width: 100% !important;"
              ></p>
            </div>
  
            <div class="faq-divider"></div>
          </div>
        </div>
      </div>
    </div>
  </section>
  