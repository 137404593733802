<div
  class="loading-overlay content-container d-flex flex-column justify-content-center align-items-center"
  *ngIf="loader"
>
  <div class="spinner pb-3"></div>
  <h1 class="pt-3 text-white">Loading Agents...</h1>
</div>

<div *ngIf="!loader">
  <div class="container-fluid d-flex flex-column align-items-center">
    <div
      class="d-flex flex-column justify-content-start text-center text-lg-start w-100"
    >
      <h1 class="mt-5 text-center">
        With Agents worldwide, we're always <br />right where you need us
      </h1>

      <p class="text-primary my-2 text-center" style="font-size: 1.6em">
        Find your Local Agents
      </p>
    </div>
    <div class="row col-12 d-flex justify-content-center">
      <div
        class="col-4 bg-light p-3 me-0 me-lg-3 col-12 col-lg-3 mt-5"
        style="border: 1px solid #e8edf3"
      >
        <div class="card">
          <div
            class="card-header d-flex justify-content-between align-items-center"
            [ngClass]="{ expanded: isExpanded }"
            id="headingOne"
            (click)="toggleCollapse('collapseOne', 'Asia', 'headingOne')"
          >
            <p>Asia</p>
            <i class="bi bi-chevron-down text-success"></i>
          </div>

          <div
            id="collapseOne"
            class="collapse"
            aria-labelledby="headingOne"
            data-parent="#accordion"
          >
            <div class="card-body">
              <ul class="justify-content-start text-start">
                <li
                  *ngFor="let country of AsianCountries"
                  (click)="changeImage(country.name)"
                  [class.active]="activeItem === country.name"
                >
                  &rsaquo; &nbsp; {{ country.name }} - {{ country.language }}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="card">
          <div
            class="card-header d-flex justify-content-between align-items-center"
            id="headingTwo"
            [ngClass]="{ expanded: isExpanded }"
            (click)="toggleCollapse('collapseTwo', 'europe', 'headingTwo')"
          >
            <p>Europe</p>

            <i class="bi bi-chevron-down text-success"></i>
          </div>
          <div
            id="collapseTwo"
            class="collapse"
            aria-labelledby="headingTwo"
            data-parent="#accordion"
          >
            <div class="card-body">
              <ul class="justify-content-start text-start">
                <li
                  *ngFor="let country of EuropeanCountries"
                  (click)="changeImage(country.name)"
                  [class.active]="activeItem === country.name"
                >
                  &rsaquo; &nbsp; {{ country.name }} - {{ country.language }}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="card">
          <div
            class="card-header d-flex justify-content-between align-items-center"
            id="headingThree"
            [ngClass]="{ expanded: isExpanded }"
            (click)="
              toggleCollapse('collapseThree', 'southAmerica', 'headingThree')
            "
          >
            <p>Central & South America</p>
            <i class="bi bi-chevron-down text-success"></i>
          </div>
          <div
            id="collapseThree"
            class="collapse"
            aria-labelledby="headingThree"
            data-parent="#accordion"
          >
            <div class="card-body">
              <ul class="justify-content-start text-start">
                <li
                  *ngFor="let country of CentralAndSouthAmericanCountries"
                  (click)="changeImage(country.name)"
                  [class.active]="activeItem === country.name"
                >
                  &rsaquo; &nbsp; {{ country.name }} - {{ country.language }}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="card">
          <div
            class="card-header d-flex justify-content-between align-items-center"
            id="headingFour"
            [ngClass]="{ expanded: isExpanded }"
            (click)="toggleCollapse('collapseFour', 'africa', 'headingFour')"
          >
            <p>Africa</p>
            <i class="bi bi-chevron-down text-success"></i>
          </div>
          <div
            id="collapseFour"
            class="collapse"
            aria-labelledby="headingFour"
            data-parent="#accordion"
          >
            <div class="card-body">
              <ul class="justify-content-start text-start">
                <li
                  *ngFor="let country of AfricanCountries"
                  (click)="changeImage(country.name)"
                  [class.active]="activeItem === country.name"
                >
                  &rsaquo; &nbsp; {{ country.name }} - {{ country.language }}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <!-- <div class="card">
        <div
          class="card-header d-flex justify-content-between align-items-center"
          id="headingFive"
          [ngClass]="{ expanded: isExpanded }"
          (click)="toggleCollapse('collapseFive', 'MiddleEast', 'headingFive')"
        >
          <p>Middle East</p>
          <i class="bi bi-chevron-down text-success"></i>
        </div>
        <div
          id="collapseFive"
          class="collapse"
          aria-labelledby="headingFive"
          data-parent="#accordion"
        >
          <div class="card-body">
            <ul class="justify-content-start text-start">
              <li
                *ngFor="let country of MiddleEastCountries"
                (click)="changeImage(country.name)"
                [class.active]="activeItem === country.name"
              >
                &rsaquo; &nbsp; {{ country.name }} - {{ country.language }}
              </li>
            </ul>
          </div>
        </div>
      </div> -->
        <div class="card">
          <div
            class="card-header d-flex justify-content-between align-items-center"
            id="headingSix"
            [ngClass]="{ expanded: isExpanded }"
            (click)="
              toggleCollapse('collapseSix', 'NorthAmerica', 'headingSix')
            "
          >
            <p>North America</p>
            <i class="bi bi-chevron-down text-success"></i>
          </div>
          <div
            id="collapseSix"
            class="collapse"
            aria-labelledby="headingSix"
            data-parent="#accordion"
          >
            <div class="card-body">
              <ul class="justify-content-start text-start">
                <li
                  *ngFor="let country of NorthAmericanCountries"
                  (click)="changeImage(country.name)"
                  [class.active]="activeItem === country.name"
                >
                  &rsaquo; &nbsp; {{ country.name }} - {{ country.language }}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="card">
          <div
            class="card-header d-flex justify-content-between align-items-center"
            id="headingSeven"
            [ngClass]="{ expanded: isExpanded }"
            (click)="toggleCollapse('collapseSeven', 'Oceania', 'headingSeven')"
          >
            <p>Oceania & Pacific</p>
            <i class="bi bi-chevron-down text-success"></i>
          </div>
          <div
            id="collapseSeven"
            class="collapse"
            aria-labelledby="headingSeven"
            data-parent="#accordion"
          >
            <div class="card-body">
              <ul class="justify-content-start text-start">
                <li
                  *ngFor="let country of OceaniaCountries"
                  (click)="changeImage(country.name)"
                  [class.active]="activeItem === country.name"
                >
                  &rsaquo; &nbsp; {{ country.name }} - {{ country.language }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div
        class="py-3 col-12 col-lg-8 text-center d-flex align-items-center mt-5 bg-light mapContainer"
      >
        <div
          class="overlayCard py-2 text-start"
          #overlayCard
          style="
            border: 1px solid var(--newsCardBorder);
            background-color: var(--navbarBgColor);
          "
        >
          <div style="width: 100%; position: relative" class="mb-3">
            <div
              style="
                position: sticky;
                top: 0;
                width: 100%;
                padding: 4% 2% 2% 2%;
                border-bottom: 1px solid rgba(63, 63, 63, 0.421);
                z-index: 1;
              "
            >
              <h2 [innerHTML]="cardCountryName"></h2>
            </div>
          </div>

          <div class="content-container">
            <div *ngIf="air.length > 0" class="ps-2 mt-2 frightdiv">
              <h2 style="color: var(--txtDark)" class="fs-5 text-start">
                Air Freight
              </h2>
              <div class="agent-container" *ngFor="let agent of air">
                <!-- <ul class="agent">
                <li style="font-weight: 600" [innerHTML]="agent.name"></li>
                <li style="font-weight: 500" [innerHTML]="agent.contact"></li>
                <div style="width: 100%"></div><br />
                <li>Phone: {{agent.phone}}</li>
                <li>Email: {{agent.email}}</li>
                <li>City: {{agent.city}} </li>
              </ul> -->
                <div class="agent d-flex flex-row">
                  <!-- &#8226;  -->
                  &nbsp;
                  <div class="text-start">
                    <!-- <span style="font-weight: 600" [innerHTML]="agent.name"></span><br /> -->
                    Address: <span [innerHTML]="agent.contact"></span><br />

                    <!-- <div style="width: 100%"></div><br /> -->

                    <!-- Phone: <span class="contactInfo" [innerHTML]="agent.phone"></span><br /> -->
                    <!-- Email: <span class="contactInfo" [innerHTML]="agent.email"></span><br /> -->
                    City:
                    <span class="contactInfo" [innerHTML]="agent.city"></span>
                  </div>
                </div>
                <hr class="divider" />
                <div style="width: 100%"></div>
              </div>
            </div>

            <div *ngIf="sea.length > 0" class="ps-2 mt-2 frightdiv">
              <h2 style="color: var(--txtDark)" class="fs-5 text-start">
                Sea Freight
              </h2>
              <div class="agent-container" *ngFor="let agent of sea">
                <div class="agent d-flex flex-row">
                  &nbsp;
                  <div class="text-start">
                    <!-- <span style="font-weight: 600" [innerHTML]="agent.name"></span><br /> -->
                    <span
                      style="font-weight: 500"
                      [innerHTML]="agent.contact"
                    ></span
                    ><br />

                    <!-- <div style="width: 100%"></div><br /> -->

                    <!-- Phone: <span class="contactInfo" [innerHTML]="agent.phone"></span><br /> -->
                    <!-- Email: <span class="contactInfo" [innerHTML]="agent.email"></span><br /> -->
                    City:
                    <span class="contactInfo" [innerHTML]="agent.city"></span>
                  </div>
                </div>
                <hr class="divider" />
                <div style="width: 100%"></div>
              </div>
            </div>

            <div *ngIf="road.length > 0" class="ps-2 mt-2 frightdiv">
              <h2 style="color: var(--txtDark)" class="fs-5 text-start">
                Road Freight
              </h2>
              <div class="agent-container" *ngFor="let agent of road">
                <div class="agent d-flex flex-row">
                  &nbsp;
                  <div class="text-start">
                    <!-- <span style="font-weight: 600" [innerHTML]="agent.name"></span><br /> -->
                    <span
                      style="font-weight: 500"
                      [innerHTML]="agent.contact"
                    ></span
                    ><br />

                    <!-- <div style="width: 100%"></div><br /> -->

                    <!-- Phone: <span class="contactInfo" [innerHTML]="agent.phone"></span><br /> -->
                    <!-- Email: <span class="contactInfo" [innerHTML]="agent.email"></span><br /> -->
                    City:
                    <span class="contactInfo" [innerHTML]="agent.city"></span>
                  </div>
                </div>
                <hr class="divider" />
                <div style="width: 100%"></div>
              </div>
            </div>

            <div *ngIf="rail.length > 0" class="ps-2 mt-2 frightdiv">
              <h2 style="color: var(--txtDark)" class="fs-5 text-start">
                Rail Freight
              </h2>
              <div class="agent-container" *ngFor="let agent of rail">
                <div class="agent d-flex flex-row">
                  &nbsp;
                  <div class="text-start">
                    <!-- <span style="font-weight: 600" [innerHTML]="agent.name"></span><br /> -->
                    <span
                      style="font-weight: 500"
                      [innerHTML]="agent.contact"
                    ></span
                    ><br />

                    <!-- <div style="width: 100%"></div><br /> -->

                    <!-- Phone: <span class="contactInfo" [innerHTML]="agent.phone"></span><br /> -->
                    <!-- Email: <span class="contactInfo" [innerHTML]="agent.email"></span><br /> -->
                    City:
                    <span class="contactInfo" [innerHTML]="agent.city"></span>
                  </div>
                </div>
                <hr class="divider" />
                <div style="width: 100%"></div>
              </div>
            </div>

            <!-- <div *ngFor="let category of Object.keys(countryAgents)">
            <h3>{{ category }}</h3>
            <div class="agent-container" *ngFor="let agent of countryAgents[category]">
              <span class="agent">
                <span style="font-weight: 600" [innerHTML]="agent.companyName"></span><br />
                <span style="font-weight: 500" [innerHTML]="agent.address"></span><br />

                <div style="width: 100%"></div><br />

                Phone: <span class="contactInfo" [innerHTML]="agent.phone"></span><br />
                Email: <span class="contactInfo" [innerHTML]="agent.email"></span><br />
                Tel: <span class="contactInfo" [innerHTML]="agent.tel"></span>
              </span>
              <hr class="divider" />
              <div style="width: 100%"></div>
            </div>
          </div> -->
          </div>

          <div class="card-footer ps-3 pb-4">
            <div style="border-top: 1px solid rgba(63, 63, 63, 0.421)"></div>
            <span style="color: var(--txtDark); font-size: 0.8rem"
              >*for further queries please contact our
            </span>
            <br />
            <a
              style="
                color: var(--text-primary);
                font-size: 0.8rem;
                font-family: Poppins;
                font-weight: 500;
                text-decoration: underline;
              "
              class="ps-2"
              href="/support"
              >Customer Support</a
            >
          </div>
        </div>

        <img #mapImg [src]="imgPath" alt="" class="map-img d-none d-lg-flex" />
      </div>
    </div>
  </div>
</div>
<app-leftimage-sm [leftImageData]="leftImageInfo[0]"></app-leftimage-sm>
