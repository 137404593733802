import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BlogComponent } from './blog.component';
import { RightimageModule } from '../../shared/components/rightimage/rightimage.module';
import { BlogDetailsComponent } from './blog-details/blog-details.component';

@NgModule({
  declarations: [BlogComponent, BlogDetailsComponent],
  imports: [CommonModule, RightimageModule],
})
export class BlogModule { }
