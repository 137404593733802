<div *ngIf="!showErrorModal">
    <div class="modal-overlay" (click)="closeModal($event)">
        <div class="modal-content py-5 px-3" (click)="$event.stopPropagation()">
            <h2 class="fs-4 text-primary text-center mb-2">{{title}}</h2>
            <p class="fs-6 mt-3 lh-base text-center mb-2">{{message}}</p>
            <div
                class="col-12 d-flex flex-column flex-lg-row align-items-center justify-content-center mt-2 gap-lg-0 gap-4 ">
                <button type="button" (click)="closeModal($event)"
                    class="btn btn-outline-primary rounded-circle d-flex align-items-center justify-content-center align-self-center mx-auto my-auto">
                    <i class="bi bi-check fs-3"></i>
                </button>
            </div>
        </div>
    </div>
</div>

<div *ngIf="showErrorModal">
    <div class="modal-overlay" (click)="closeModal($event)">
        <div class="modal-content py-5 px-3" (click)="$event.stopPropagation()" >
            <h2 class="fs-4 fw-semibold text-center mb-2 mt-4" style="color: #EF4455;">Invalid Code</h2>
            <p class="fs-6 mt-4 lh-base text-center mb-3">The organization code you entered is incorrect. Please cancel
                and re-enter the code. If you do not have a code, click sign up and get started.</p>
            <div
                class="col-12 d-flex flex-column flex-lg-row align-items-center justify-content-center gap-3 mt-2 mb-2">
                <button type="button" (click)="closeModal($event)"
                    class="btn btn-outline-primary d-flex align-items-center justify-content-center  w-25"
                   >
                    Cancel
                </button>
                <button type="button" (click)="SignUp()"
                    class="btn btn-primary d-flex align-items-center justify-content-center w-25"
                   >
                    Sign Up
                </button>
            </div>
        </div>
    </div>
</div>