<div class="modal-overlay" (click)="closeModal($event)">
    <div class="modal-content py-5 px-3" (click)="$event.stopPropagation()">
        <h2 class="fs-4 text-primary text-center mb-2">Thank you for your message!
        </h2>
        <p class="fs-6 mt-3 lh-base text-center mb-2">We appreciate your query/feedback and will review it shortly. If a response is needed, we’ll get back to you within 1-2 business days.
        </p>

        <div
            class="col-12 d-flex flex-column flex-lg-row align-items-center justify-content-center mt-2 gap-lg-0 gap-4 ">

            <button type="button" (click)="closeModal($event)"
                class="btn btn-outline-primary rounded-circle d-flex align-items-center justify-content-center align-self-center mx-auto my-auto">
                <i class="bi bi-check fs-3"></i>
            </button>

        </div>
    </div>
</div>