<div
  class="loading-overlay content-container d-flex flex-column justify-content-center align-items-center"
  *ngIf="loader">
  <div class="spinner pb-3"></div>
  <h1 class="pt-3 text-white">Loading Agents...</h1>
</div>

<section class="container-fluid bg-light" *ngIf="!loader">
  <!-- Row 1: Back to News & Media -->
  <div class="row align-items-center">
    <div class="col-lg-1"></div>
    <div class="col-lg-4 col-md-12">
      <!-- <p class="fw-medium back-button" style="cursor: pointer" (click)="navigateToBlog()">
        <i class="bi bi-chevron-left fw-bolder px-3"></i>
        Back to News & Media
      </p> -->
      <p
        class="fw-medium back-button d-flex align-items-center justify-content-start"
        id="back"
        (click)="navigateToBlog()"
        style="cursor: pointer; font-size: 1em !important"
        routerLink>
        <i class="bi bi-chevron-left fw-bolder h2-icon me-2"></i>
        Go Back to News & Media
      </p>
    </div>
    <div class="col-lg-6 col-md-12"></div>
  </div>

  <!-- Row 2: Rest of the Content -->
  <div class="row align-items-center">
    <div class="col-lg-1"></div>
    <div class="col-lg-4 col-md-12 d-flex justify-content-between flex-column">
      <div class="text-content my-5">
        <h1
          [innerHTML]="article?.title"
          class="text-center text-lg-start fs-3"></h1>
        <p
          class="text-primary text-start fw-bold me-3 me-md-4 me-xl-4 me-xxl-5 pe-lg-2 pe-0"
          style="
            font-size: 1.2em !important;
            padding: 0 !important;
            margin: 0 !important;
          ">
          Source: {{ article?.source.uri }}
        </p>
      </div>
      <div
        *ngIf="article?.authors.length > 0"
        class="d-flex col-12 justify-content-lg-start justify-content-between text-center text-lg-start ps-4 ps-lg-0 align-items-center my-5">
        <i
          class="bi bi-person-circle text-primary"
          style="font-size: 64px; border-radius: 8px"></i>
        <div class="col-12 col-lg-5 text-start mx-3">
          <h2 class="text-primary fs-5 fw-medium author">
            {{ article?.authors[0].name }}
          </h2>
          <span> {{ article?.date }}</span>
        </div>
      </div>
    </div>
    <div class="col-lg-1 col-md-2"></div>
    <div class="col-lg-6 col-md-12">
      <img [src]="article?.image" alt="Logistics solutions" class="img-fluid" />
    </div>
  </div>
</section>

<div class="container-two my-5">
  <div
    class="border-bottom pb-3 mb-3"
    style="border-width: 1.5px; border-color: #8c9db6">
    <div
      class="row justify-content-lg-between align-items-lg-start justify-content-center">
      <div
        class="col-auto d-flex flex-row align-items-start gap-4 pb-4"
        style="text-align: left">
        <a
          class="p-3 bg-light text-primary"
          href="/freight/air"
          style="text-decoration: none">Air Freight</a>
        <a
          class="p-3 bg-light text-primary"
          href="/services/customs-clearance"
          style="text-decoration: none">Custom Clearance</a>
        <a class="p-3 bg-light text-primary" style="text-decoration: none">Get A
          Quote</a>
      </div>
      <div class="col-auto d-flex flex-column gap-2 pb-3 text-start">
        <div class="share-text">Share this Page</div>
        <div class="d-flex gap-4">
          <i
            class="bi bi-linkedin fs-2 text-primary"
            style="cursor: pointer"
            (click)="share('linkedin')"></i>
          <i
            class="bi bi-twitter-x fs-2 text-primary"
            style="cursor: pointer"
            (click)="share('twitter')"></i>
          <i
            class="bi bi-facebook fs-2 text-primary"
            style="cursor: pointer"
            (click)="share('facebook')"></i>
        </div>
      </div>
    </div>
  </div>
  <div class="newsDiv mt-5">
    <h1 class="m-0 text-start">{{ article?.title }}</h1>
    <div style="width: 100%">
      <div class="d-flex flex-row align-items-center">
        <div>
          <p
            class="m-0 text-start"
            *ngFor="
              let paragraph of description | slice : 0 : 3;
              let last = last
            ">
            {{ paragraph
            }}<span
              *ngIf="last"
              style="
                font-size: 1.2em !important;
                font-weight: 400 !important;
                line-height: normal !important;
              ">
              <a
                [href]="articleUrl"
                target="_blank"
                class="fst-italic text-primary">. . . Read full article
                here</a></span>
          </p>
        </div>
      </div>
    </div>
    <!-- <div> -->
    <!-- <img style="max-width: 100%; max-height: 90%; border-radius: 10px" [src]="article?.image" /> -->
    <!-- </div> -->
    <!-- <div style="width: 100%">
      <p class="m-0" [innerHTML]="article?.content"></p>
    </div> -->
  </div>

  <div
    class="border-bottom pb-3 my-5"
    style="border-width: 1.5px; border-color: #8c9db6"></div>

  <div
    *ngIf="article?.authors.length > 0"
    class="border-bottom pb-3 my-5 col-12"
    style="border-width: 1.5px; border-color: #8c9db6">
    <h2 class="p-0 m-0 text-start">About the Author</h2>
    <div
      class="d-flex col-12 justify-content-lg-start justify-content-between align-items-center">
      <!-- <img [src]="article?.image" alt="" style="width: 64px; height: 64px; border-radius: 8px" /> -->
      <i
        class="bi bi-person-circle text-primary"
        style="font-size: 64px; border-radius: 8px"></i>
      <div class="py-5 my-5 mx-3"></div>
      <div class="col-12 col-lg-5 text-start">
        <h2 class="text-primary fs-5 fw-medium author">
          {{ article?.authors[0].name }}
        </h2>
        <span>{{ article?.date }}</span>
      </div>
    </div>
  </div>
</div>
