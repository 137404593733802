import { Component, EventEmitter, Output } from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { Router } from '@angular/router';

@Component({
  selector: 'app-my-intercargo-menu',
  templateUrl: './myintercargomenu.component.html',
  styleUrl: './myintercargomenu.component.css'
})
export class MyIntercargoMenuComponent {
  @Output() closeMenuEvent = new EventEmitter<void>();  // Controls visibility of the mega menu

  constructor(private router: Router) { }
  closeMenu(route?: string) {
    if (route) {
      const queryParams = { path: 'megaMenu' };
      this.closeMenuEvent.emit();
      this.router.navigate([route], { queryParams })
    } else {
      this.closeMenuEvent.emit(); // Emitting an event when the button is clicked
    }
  }
}
