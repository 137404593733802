import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';


@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrl: './privacy-policy.component.css'
})
export class PrivacyPolicyComponent {
  @ViewChild('faqList') faqList!: ElementRef;
  isDisabled = true;
  activeItem = 'general';

  constructor(private title: Title, private meta: Meta){}
  enableSearchButton() {
    this.isDisabled = false;
  }
  policy = {
    heading: 'General Information',
    description: ` Here at Intercargo, we are committed to protecting your privacy online as highly as we would with our client 
information in our booking system. You have control over the information we store about you and what 
types of information (if any) you would like to receive from us. We collect general statistical information 
about visitors to our website for general reporting and to improve future content.  <br />   <br />  We also collect personal information about you online to provide you with a range of personalized services. 
Some of these services are not accessible unless you supply individual identifiable information.  <br /> <br />  We also follow strict guidelines in the storage and disclosure of information which you have given us, to prevent 
unauthorized access. This is in line with the laws and regulations of the United Kingdom. This means that when 
you contact us, we will ask you for proof of identity before we can disclose personal information to you, or 
discuss your booking with.`,
  };

  // ngAfterViewInit() {
  //   this.applyScrollBehavior();
  // }

  // @HostListener('window:resize', ['$event'])
  // onResize(event: Event) {
  //   this.applyScrollBehavior();
  // }

  // ngOnDestroy() {
  //   window.removeEventListener('resize', this.applyScrollBehavior);
  // }

  // applyScrollBehavior() {
  //   const screenWidth = window.innerWidth;

  //   if (screenWidth < 992) {
  //     const listItems = this.faqList.nativeElement.querySelectorAll('li');

  //     listItems.forEach((item: HTMLElement) => {
  //       item.addEventListener('click', this.scrollHandler.bind(this));
  //     });
  //   } else {
  //     const listItems = this.faqList.nativeElement.querySelectorAll('li');

  //     listItems.forEach((item: HTMLElement) => {
  //       item.removeEventListener('click', this.scrollHandler.bind(this));
  //     });
  //   }
  // }

  // private scrollHandler(event: Event) {
  //   const item = event.target as HTMLElement;
  //   const faqList = this.faqList.nativeElement;

  //   const activeItem = faqList.querySelector('.active');
  //   if (activeItem) {
  //     activeItem.classList.remove('active');
  //   }
  //   item.classList.add('active');
  //   // item.scrollIntoView({ inline: 'center', behavior: 'smooth' });
  // }

  getFAQs(faq: string) {
    this.activeItem = faq;
    switch (faq) {
      case 'general':
        this.policy = {
          heading: 'General Information',
          description: ` Here at Intercargo, we are committed to protecting your privacy online as highly as we would with our client 
information in our booking system. You have control over the information we store about you and what 
types of information (if any) you would like to receive from us. We collect general statistical information 
about visitors to our website for general reporting and to improve future content. <br /> <br />  We also collect personal information about you online to provide you with a range of personalized services. 
Some of these services are not accessible unless you supply individual identifiable information. <br /> <br />  We also follow strict guidelines in the storage and disclosure of information which you have given us, to prevent 
unauthorized access. This is in line with the laws and regulations of the United Kingdom. This means that when 
you contact us, we will ask you for proof of identity before we can disclose personal information to you, or 
discuss your booking with.`,
        };
        break;
      case 'sensitive':
        this.policy = {
          heading: 'Sensitive Personal Data',
          description: `You may be required to provide information which is defined as Sensitive Personal Data by the Data Protection Act (1998) - for example, your full address and postcode (if applicable). We do not seek any other sensitive personal data online but if you provide us with any other sensitive personal data by telephone, email or by other means, 
we will treat that sensitive personal data in accordance with this privacy policy. <br /> <br /> In providing this information, you are giving your explicit consent for Intercargo to collect, process, disclose or transfer, and store your Sensitive Personal Data for the purposes of fulfilling your requirements. <br /> <br />  Your Sensitive Personal Data will not be disclosed to any third-parties except to the extent and the purpose that Intercargo may be required to do so by any law, legislation, enactment, safety reason, or for the fulfilment of this contract between us in order to satisfy your requirements, and that the disclosure is safe.`,
        };
        break;
      case 'transfer':
        this.policy = {
          heading: 'Transfers of data',
          description: `We may need to send your data (including Sensitive Personal Data) outside the UK so that we can comply with your requirements or for safety or legal reasons. In providing your Sensitive Personal Data you are also giving your explicit consent to the transfer of that Sensitive Personal Data outside the UK so that we may comply with your requirements or for safety or legal reasons. <br /> <br /> Please note that in certain countries it is a mandatory requirement for Intercargo to disclose certain of your client data. Accordingly, any information - including Sensitive Personal Data - which Intercargo holds about you and your shipment arrangements may be disclosed to those agencies in the countries to which you are sending your shipment. <br /> <br /> Your Sensitive Personal Data will not be disclosed to any third-parties except to the extent and the purpose that Intercargo may be required to do so by any law, legislation, enactment, safety reason, or for the fulfilment of this contract between us in order to satisfy your requirements, and that the disclosure is safe. <br /> <br /> You understand and accept that if your data is disclosed to third parties outside the European Economic Area (the European Community, Iceland, Norway and Liechtenstein), those third parties may reside in territories which may not have suitable data protection provisions in place, but every effort will be made to ensure that your data is processed only for the purpose set out above, and for no other purposes.
By using this website, you consent to the collection and use of this information by intercargo. If we change our privacy policy we will inform you by posting these changes on this page.`,
        };
        break;
      case 'data':
        this.policy = {
          heading: 'What we collect and why?',
          description: `We ask for information about you in seven areas of our website as follows: <br /> <br />
When making an online booking
(Purposes - "Accounts and Records", "Advertising, Marketing and Public Relations", "Advertising, Marketing and Public Relations for Others", "Software Development and Testing", "Research", "The Consideration of Complaints")
Here the information we will ask you for includes Consignors and consignees: name; contact details such as phone and e mail; credit card details; your delivery preference and occasionally information pertaining to your shipment. <br /> <br />We need all this information to process a shipment for you and in order to be able to contact you if we need more information or if there are any changes to your booking/shipment. We will retain this information to service any post-flight queries, and may use it to help us develop technology to better serve you in the future. This information will not be given to any third party, except to the extent and for the purpose we may be required to do so by any law or for the fulfilment of this contract between us. `,
        };
        break;
      case 'security':
        this.policy = {
          heading: 'Is your credit card number secure online?',
          description: `We use the latest SSL (Secure Socket Layer) technology, which ensures that any communications between your computer and ourselves is unreadable by anyone else. Your details are communicated in a secure encrypted format and we can check that the information has not been tampered with in any way.<br /> <br /> Most browsers have some type of indicator to let you know if you have established a secure connection. If you are not sure, then consult the help section of your browser. If your browser does not support SSL technology then we recommend that you update to the latest version.`,
        };
        break;
      case 'cookie':
        this.policy = {
          heading: 'What are cookies and why do we use them?',
          description: `Cookies are a standard method of registering user preferences when people use websites, to ensure a better and more personalized experience. These preferences are stored in a file on your computer's hard disk drive. For example, a cookie would contain your preferred country of residence so we do not have to ask you this question each time you visit. <br /> <br />
          The cookies we pass to you are anonymous and do not hold any personal information on you or reveal your identity. These cookies will not be used to track your interaction with any websites provided by any other party.`,
        };
        break;
      default:
    }
  }

  checkInputValue(event: FocusEvent) {
    const inputElement = event.target as HTMLInputElement;
    if (!inputElement.value.trim()) {
      this.isDisabled = true;
    }
  }

  rightImageData = {
    heading: 'Privacy Policy',
    description: `Here at Intercargo, We follow strict guidelines in the storage and disclosure of information which you have given us, to prevent unauthorized access.`,
    img: '../../../assets/imgs/Privacy.png',
    btnTxt1: '',
    btnLink1: '',
    btnTxt2: ' ',
    btnLink2: '',
  };

  ngOnInit():void{
    function toCapitalize(str: any): string {
      if (!str) return '';
      return str.charAt(0).toUpperCase() + str.slice(1);
  }
  this.title.setTitle(toCapitalize("Privacy Policy")  + " - Intercargo Logistics");
   
  this.meta.removeTag('name="author"');
  this.meta.removeTag('property="og:title"');
  this.meta.removeTag('property="og:description"');
  // this.meta.removeTag('property="og:image"');
  this.meta.addTags([
   
    { name: 'author', content: 'Intercargo Logistics' },
    { property: 'og:title', content: 'Privacy Policy' },
    {
      property: 'og:description', content: "Here at Intercargo, We follow strict guidelines in the storage and disclosure of information which you have given us, to prevent unauthorized access." },
    // { property: 'og:image', content: 'https://www.intercargo.com/assets/imgs/AirRight1.png' }
  ]); 

  }
}
