import { Component, EventEmitter, Inject, Output} from '@angular/core';
import { Router , ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-organisationerror',
  templateUrl: './organisationerror.component.html',
  styleUrls: ['../downloadpdf-thankyou/downloadpdf-thankyou.component.css'], 
})
export class OrganisationerrorComponent {
  @Output() modalClose: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private router : Router,
  ) {
  }
 
  
  closeModal( $event: any ) {
    this.router.navigate([{outlets: {modal: null}}]);
    this.modalClose.next($event);
}

SignUp(): void {
  this.router.navigate([{outlets: {modal: null}}]);
    setTimeout(() => {
    this.router.navigate(['/members-hub/open-an-account'], { queryParams: { path: 'megaMenu' } });
  }, 0); 
}



}
