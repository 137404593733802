<div class="mega-menu">
  <div class="col-xl-5 col-xxl-4">
    <img src="../../../../assets/imgs/MegaMenu-MyInterCargo.png"
      class="img-fluid" alt="Road Freight" />
  </div>
  <!-- style="border-right: 1px solid #50575C; -->
  <div
    class="col-xl-3 col-xxl-3 pe-4 pe-xxl-5 border-right d-flex flex-column justify-content-between">
    <!-- <div class="row"> -->
    <h3>
      Keep all your data in one place which can be accessed from anywhere and
      anyplace
    </h3>
    <!-- </div> -->
    <!-- <div class="row"> -->
    <p>Let us help you 24/7 manage your supply chain needs</p>
    <!-- </div> -->
    <!-- <div class="row"> -->
    <button class="btn btn-outline-primary w-xl-100 w-xxl-75" routerLink="/blog"
      (click)="closeMenu()">
      Explore More News
    </button>
    <!-- </div> -->
  </div>

  <div class="mega-menu-column w-25 h-75">
    <h3 class="fw-semibold">LOGISTICS HUB</h3>
    <ul>
      <li><a routerLink="/MyIntercargo/conexa"
          (click)="closeMenu()">Conexa</a></li>
      <li><a routerLink="/MyIntercargo/track-it" (click)="closeMenu()">Track
          it</a></li>
      <li><a routerLink="/MyIntercargo/sailing-schedules"
          (click)="closeMenu()">Sailing Schedules</a></li>
      <li><a routerLink="/MyIntercargo/flight-schedules"
          (click)="closeMenu()">Flight Schedules</a></li>
      <!-- <li><a routerLink="/MyIntercargo/RoadSchedules" (click)="closeMenu()">Road Schedules</a></li> -->
      <li><a routerLink="/MyIntercargo/incoterms"
          (click)="closeMenu()">Incoterms</a></li>
    </ul>
  </div>

  <div class="mega-menu-column w-25 h-75">
    <h3 class="fw-semibold">MEMBERS HUB</h3>
    <ul>
      <li><a routerLink="/members-hub/download-centre"
          (click)="closeMenu()">Download Centre</a></li>
      <li><a (click)="closeMenu('/members-hub/open-an-account')">Open an
          Account</a></li>

      <!-- <li><a
        (click)="handleLinkClick(intercargo, '/members-hub/open-an-account')">Open
        an Account</a></li> -->
      <!-- <li><a routerLink="/change-password" (click)="closeMenu()">Change Password</a></li> -->
      <li><a routerLink="/members-hub/careers"
          (click)="closeMenu()">Careers</a></li>
      <li><a routerLink="/members-hub/sustainability"
          (click)="closeMenu()">Sustainability</a></li>
      <li><a routerLink="/members-hub/case-study" (click)="closeMenu()">Case
          Studies</a></li>
      <li><a routerLink="/members-hub/faqs" (click)="closeMenu()">FAQ's</a></li>
    </ul>
  </div>

</div>
