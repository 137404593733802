import {
  Component,
  ElementRef,
  HostListener,
  Inject,
  PLATFORM_ID,
  ViewChild,
  signal,
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CreateEmailComponent } from '../../shared/components/create-email/create-email.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { EmailService } from '../../services/email.service';
import { ZendeskService } from '../../services/zendesk.service';
import { isPlatformBrowser } from '@angular/common';
import { Meta, Title } from '@angular/platform-browser';
import { GeolocationService } from '../../services/geolocation.service';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { filter, pairwise } from 'rxjs';


@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  // styleUrl: '../../shared/style.css'
  styleUrl: './support.component.css',
})
export class SupportComponent {

  loader: boolean = true;
  feedbackSubject = 'Feedback';
  firstname: string = '';
  lastname: string = '';
  email: string = '';
  // {
  //   Feedback:"Excellent",
  //   Thoughts:"HEY",
  //   Subject:"Feedback"
  //   }
  constructor(private geoLocationService: GeolocationService, public dialog: MatDialog, private snackBar: MatSnackBar, private sanitizer: DomSanitizer, private emailService: EmailService, private zendeskService: ZendeskService, private title: Title, private meta: Meta, @Inject(PLATFORM_ID) private platformId: Object, public router: Router) {
    this.dialogRef = null;

    // this.setCountryFromGeoLocation()
  }
  iframeSrcs: SafeResourceUrl[] = [];
  mapIndex: number = 2;
  private rawIframeSrcs: string[] = [
    "https://maps.google.com/maps?width=100%25&height=600&hl=en&q=40.71484325591868,%20-73.83112442571424+(Intercargo%20USA%20LLC)&t=&z=17&ie=UTF8&iwloc=B&output=embed",
    "https://maps.google.com/maps?width=100%25&height=600&hl=en&q=Intercargo%20Logistics,%205925%20Airport%20Rd,%20Mississauga,%20ON%20L4V%201W1,%20Canada+()&t=&z=17&ie=UTF8&iwloc=B&output=embed",
    "https://maps.google.com/maps?width=100%25&height=780&hl=en&q=Intercargo,%20Horton%20Road%20Colnbrook,%20SL3%200AT,%20London%20Heathrow,%20United%20Kingdom+(Intercargo%20Logistics)&t=&z=18&ie=UTF8&iwloc=B&output=embed",

    "https://maps.google.com/maps?width=100%25&height=600&hl=en&q=53.402795865723824,%20-6.260175556369799+(The%20Crescent%20Building%20Northwood%20Santry,%20Dublin)&t=&z=17&ie=UTF8&iwloc=B&output=embed",

    "https://maps.google.com/maps?width=100%25&height=600&hl=en&q=Intercargo%20Logistics,2nd%20Floor%20One%20Expressway%20Gulberg%20Greens%20Interchange,%2044000,%20Islamabad,%20Pakistan+(intercargo)&t=&z=17&ie=UTF8&iwloc=B&output=embed",

    "https://maps.google.com/maps?width=100%25&height=600&hl=en&q=Dubai%20Airport%20Free%20Zone%20-%20Dubai%20-%20United%20Arab%20Emirates+(Intercargo%20Logistic)&t=&z=17&ie=UTF8&iwloc=B&output=embed"
  ];

  async setCountryFromGeoLocation() {
    this.loader = true;
    try {
      const ip = await this.geoLocationService.getIP();
      const locationData = await this.geoLocationService.getGeoLocation(ip);
      this.selectedCountry = locationData.countryCode;
      this.contactInfo();
      this.loader = false;

    } catch (error) {
      console.error('Error:', error);
      this.loader = false;
    }
    // });
  }


  async ngOnInit() {
    this.router.events.pipe(
      // Type guard to ensure event is NavigationStart
      filter((event): event is NavigationStart => event instanceof NavigationStart),
      pairwise() // Emits pairs of consecutive NavigationStart events
    ).subscribe(([previous, current]: [NavigationStart, NavigationStart]) => {
      // Comparing navigation IDs to detect refresh
      if (previous.id === current.id) {
        // Add your refresh handling logic here
      }
    });

    await this.setCountryFromGeoLocation();


    this.iframeSrcs = this.rawIframeSrcs.map(src => this.sanitizer.bypassSecurityTrustResourceUrl(src));
    function toCapitalize(str: any): string {
      if (!str) return '';
      return str.charAt(0).toUpperCase() + str.slice(1);
    }
    this.title.setTitle(toCapitalize("Support") + " - Intercargo Logistics");

    this.meta.removeTag('name="author"');
    this.meta.removeTag('property="og:title"');
    this.meta.removeTag('property="og:description"');
    // this.meta.removeTag('property="og:image"');
    this.meta.addTags([

      { name: 'author', content: 'Intercargo Logistics' },
      { property: 'og:title', content: 'Support' },
      {
        property: 'og:description', content: "Your source for sustainable logistics"
      },
      // { property: 'og:image', content: 'https://www.intercargo.com/assets/imgs/AirRight1.png' }
    ]);

  }

  @ViewChild('mapDiv') mapDiv: ElementRef | null = null;

  supportImg = '../../../assets/imgs/Support.png';
  showMap = false;
  selectedCountry: string = 'GB';
  activeTab: string = 'support';
  textAreaContent: string = '';

  phone = signal('+44 208 831 9770');
  address = signal(`HCH House, Horton Road<br>
  Colnbrook, SL3 0AT,<br>
  London Heathrow,<br>
  United Kingdom`);

  ratings = signal([
    {
      img: '../../assets/imgs/Feedback Emoji/Colourful/Excellent.png',
      label: 'Excellent',
    },
    {
      img: '../../assets/imgs/Feedback Emoji/Black n White/Look Good.png',
      label: 'Look Good',
    },
    {
      img: '../../assets/imgs/Feedback Emoji/Black n White/Fine.png',
      label: 'Fine',
    },
    {
      img: '../../assets/imgs/Feedback Emoji/Black n White/Not Good.png',
      label: 'Not Good',
    },
    {
      img: '../../assets/imgs/Feedback Emoji/Black n White/Worst.png',
      label: 'Worst',
    },
  ]);

  defaultRating = this.ratings()[0];


  shareFeedback() {

    const feedback = {
      feedback: this.defaultRating.label,
      thoughts: this.textAreaContent,
      subject: this.feedbackSubject,
      firstName: this.firstname + ' ' + this.lastname,
      email: this.email
    }
    if (this.textAreaContent != '' && this.firstname != '' && this.lastname != '' && this.email != '') {
      this.emailService.sendEmail(feedback).subscribe({
        next: (response) => {
          this.snackBar.open("Thank you for your feedback.", 'Received', {
            duration: 3000,
            panelClass: ['snackbar-success'],
            horizontalPosition: 'end',
            verticalPosition: 'top'
          });
        },
        error: (error) => {
          this.snackBar.open(error.message, 'Failed', {
            duration: 3000,
            panelClass: ['snackbar-error'],
            horizontalPosition: 'end',
            verticalPosition: 'top'
          });
        }
      });
    }
    else {
      this.snackBar.open("Please fill all fields", 'Failed', {
        duration: 3000,
        panelClass: ['snackbar-error'],
        horizontalPosition: 'end',
        verticalPosition: 'top'
      });
    }

  }

  setActiveTab(tabName: string): void {
    this.activeTab = tabName;
  }

  setRating(rating: any) {
    if (this.defaultRating.label !== rating.label) {
      this.defaultRating.img =
        '../../assets/imgs/Feedback Emoji/Black n White/' +
        this.defaultRating.label +
        '.png';
      this.defaultRating = rating;
      rating.img =
        '../../assets/imgs/Feedback Emoji/Colourful/' + rating.label + '.png';
    }
  }

  dialogRef: MatDialogRef<CreateEmailComponent> | null;

  openModal() {

    const dialogRef = this.dialog.open(CreateEmailComponent, {
      width: '900px',
      maxHeight: '760px',
      // panelClass: 'email-container',
      disableClose: true, // Optional: prevents closing the dialog by clicking outside or pressing ESC
    });

    dialogRef.afterClosed().subscribe((data) => {
      if (data.status) {
        this.snackBar.open('Sucess', data.messege, {
          duration: 3000,
          horizontalPosition: 'end',
          verticalPosition: 'top',
          panelClass: ['snackbar-success'],
        });
      }
    });
  }
  ngAfterViewInit() {
    this.loader = true;
    this.setCountryFromGeoLocation();
    // this.contactInfo();
  }

  contactInfo() {
    switch (this.selectedCountry) {
      case 'US':
        this.address.set(`
          118-35 Queens Blvd Suite 400 <br />
            Forest Hills New York NY 11375
            `);
        this.phone.set(`+1 (718) 707 0322`);
        this.mapIndex = 0;
        break;

      case 'CA':
        this.address.set(`
            5925 Airport Road <br />
Mississauga Ontario L4V 1W1
            `);
        this.phone.set(`+1 (416) 800 7547`);
        this.mapIndex = 1;
        break;

      case 'GB':
        this.address.set(`
            HCH House, Horton Road<br>
          Colnbrook, SL3 0AT,<br>
          London Heathrow,<br>
          United Kingdom
            `);
        this.phone.set(`+44 208 8319770`);
        this.mapIndex = 2;
        break;

      case 'IE':
        this.address.set(`
           The Crescent Building <br />
           Northwood Santry, <br />
           Dublin
            `);
        this.phone.set(`+353 1 893 4141`);
        this.mapIndex = 3;
        break;

      case 'PK':
        this.address.set(`
            2nd Floor One Expressway <br />
            Gulberg Greens Interchange, <br />
            44000, Islamabad, <br /> Pakistan
            `);
        this.phone.set(`+92 51 8488002`);
        this.mapIndex = 4;
        break;

      case 'AE':
        this.address.set(`
            Office No. 3E 462 - East Wing 3 <br />
Dubai Airport Freezone <br />
PO Box 116158, Dubai, <br /> U.A.E
            `);
        this.phone.set(`+971 507125439`);
        this.mapIndex = 5;
        break;

      default:
        this.address.set(`
          HCH House, Horton Road<br>
        Colnbrook, SL3 0AT,<br>
        London Heathrow,<br>
        United Kingdom
          `);
        this.phone.set(`+44 208 8319770`);
        this.mapIndex = 2;
        this.selectedCountry = 'GB'

        break;
    }
  }
  viewMap() {
    this.showMap = !this.showMap;

    if (window.innerWidth < 992) {
      // Scroll to the top of the parent container
      window.scrollTo({ top: 100, behavior: 'smooth' });
    }
  }
  hideMap() {
    this.showMap = !this.showMap;
  }

  loadZendeskWidget() {
    this.zendeskService.activateZendeskChat();
  }
  ngOnDestroy() {
    if (isPlatformBrowser(this.platformId)) {
      const scriptElement = document.getElementById('ze-snippet');
      if (scriptElement) {
        scriptElement.remove();
      }
    }
  }
}
