<div class="mega-menu">

  <div class="col-4 col-xl-6 col-xxl-6 border-right pe-xl-5 pe-5">
    <div class="row justify-content-center">

      <!-- <iframe id="videoFrame" width="200" height="294"
        src="https://www.youtube.com/embed/llxdDudGjH0?enablejsapi=1&rel=0" frameborder="0"
        allow="autoplay;"
        appVideoHover></iframe> -->

      <video id="videoFrame" height="294" controls poster="../../../../assets/imgs/solThumb.png" (mouseenter)="playVideo($event)" (mouseleave)="pauseVideo($event)" playsinline>
        <source src="../../../../assets/Compress 1st Promo Video.mp4" type="video/mp4">
        Your browser does not support the video tag.
      </video>

    </div>

    <div class="row mt-5">
      <div class="col-xl-6 col-xxl-5">
        <h3>Get to know more about how we operate</h3>
      </div>
      <div class="col-1"></div>
      <div class="col-xl-5 col-xxl-6">
        <button class="btn btn-outline-primary w-100" (click)="watch()">Watch Full Video</button>
      </div>
    </div>
  </div>

  <div class="mega-menu-column me-4">
    <h3 class="fw-semibold">FREIGHT</h3>
    <ul>
      <li routerLink="/freight/air" (click)="closeMenu()"><a>Air</a></li>
      <li routerLink="/freight/sea" (click)="closeMenu()"><a>Sea</a></li>
      <li routerLink="/freight/road" (click)="closeMenu()"><a>Road</a></li>
      <li routerLink="/freight/rail" (click)="closeMenu()"><a>Rail</a></li>
      <li routerLink="/freight/courier" (click)="closeMenu()"><a>Courier</a></li>
    </ul>
  </div>

  <div class="mega-menu-column me-3">
    <h3 class="fw-semibold">SERVICES</h3>
    <ul>
      <li routerLink="/services/customs-clearance" (click)="closeMenu()"><a>Customs Clearance</a></li>
      <li routerLink="/services/warehousing" (click)="closeMenu()"><a>Warehousing</a></li>
      <li routerLink="/services/project-cargo" (click)="closeMenu()"><a>Project Cargo</a></li>
      <li routerLink="/services/aircraft-charter" (click)="closeMenu()"><a>Aircraft Charter</a></li>
      <li routerLink="/services/dangerous-goods" (click)="closeMenu()"><a>Dangerous Goods</a></li>
      <li routerLink="/services/perishable-goods" (click)="closeMenu()"><a>Perishable Goods</a></li>
      <li routerLink="/services/same-day-delivery" (click)="closeMenu()"><a>Same Day Delivery</a></li>
      <li routerLink="/services/order-management" (click)="closeMenu()"><a>Order Management</a></li>
      <li routerLink="/services/onboard-courier" (click)="closeMenu()"><a>Onboard Courier</a></li>
      <li routerLink="/services/agency-representation" (click)="closeMenu()"><a>Agency Representation</a></li>
    </ul>
  </div>

  <div class="mega-menu-column me-5 pe-5">
    <h3 class="fw-semibold">INDUSTRIES</h3>
    <ul>
      <li routerLink="/industries/fashion" (click)="closeMenu()"><a>Fashion</a></li>
      <li routerLink="/industries/consumer-goods" (click)="closeMenu()"><a>Consumer Goods</a></li>
      <li routerLink="/industries/high-tech&electronics" (click)="closeMenu()"><a>High Tech & Electronics</a></li>
      <li routerLink="/industries/pharma&healthcare" (click)="closeMenu()"><a>Pharma & Healthcare</a></li>
      <li routerLink="/industries/offshore&ship-spares" (click)="closeMenu()"><a>Offshore & Ship Spares</a></li>
      <li routerLink="/industries/aerospace&defence" (click)="closeMenu()"><a>Aerospace & Defence</a></li>
      <li routerLink="/industries/e-commerce" (click)="closeMenu()"><a>E-commerce</a></li>
      <li routerLink="/industries/oil&gas" (click)="closeMenu()"><a>Oil & Gas</a></li>
      <li routerLink="/industries/automotive" (click)="closeMenu()"><a>Automotive</a></li>
    </ul>
  </div>

</div>