import { Component, ElementRef, HostListener, ViewChild, Inject, PLATFORM_ID } from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css' ]
})
export class FooterComponent {
  logoUrl: string = './assets/logo.png';
  facebookIconUrl: string = './assets/facebook.png';
  twitterIconUrl: string = './assets/twitter.png';
  linkedinIconUrl: string = './assets/linkedin.png';
  arrowIconUrl: string = './assets/arrow-up.png';
  privacyPolicyUrl: string = 'https://www.example.com/privacy';
  carriageConditionsUrl: string = 'https://www.example.com/terms';

  isMobile: boolean = isPlatformBrowser(this.platformId) ? window.innerWidth < 1200 : false;

  icons = [
    { path: '../../../assets/ico/TwitterBlue.ico', route: 'https://twitter.com/intercargo1?lang=en' },
    { path: '../../../assets/ico/LinkedinBlue.ico', route: 'https://www.linkedin.com/company/intercargo-limited' },
    { path: '../../../assets/ico/InstaBlue.ico', route: 'https://www.instagram.com/intercargo_logistics/' },
    { path: '../../../assets/ico/YoutubeBlue.ico', route: 'https://www.youtube.com/@intercargomedia9151' },
    { path: '../../../assets/ico/FbBlue.ico', route: 'https://www.facebook.com/intercargologistics' },
  ];

  constructor(private elRef: ElementRef, @Inject(PLATFORM_ID) private platformId: Object) {}

  changeIconPath(icon: { path: string, route: string }, isHovered: boolean): void {
    const basePath = '../../../assets/ico/';
    if (isHovered) {
      const iconName = icon.path.split('/').pop()?.replace('Blue.ico', '');
      icon.path = `${basePath}${iconName}.ico`; // assuming white icons exist
    } else {
      const iconName = icon.path.split('/').pop()?.replace('.ico', '');
      icon.path = `${basePath}${iconName}Blue.ico`;
    }
  }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      document.addEventListener('click', this.handleGlobalClick.bind(this));
    }
  }

  ngOnDestroy() {
    if (isPlatformBrowser(this.platformId)) {
    document.removeEventListener('click', this.handleGlobalClick.bind(this));
    }
  }

  @ViewChild('Consolidation') Consolidation!: MatExpansionPanel;
  @ViewChild('Air') Air!: MatExpansionPanel;
  @ViewChild('Sea') Sea!: MatExpansionPanel;
  @ViewChild('Road') Road!: MatExpansionPanel;

  handleGlobalClick(event: Event) {
    const clickedInside = this.elRef.nativeElement.contains(event.target);
    if (!clickedInside) {
      this.handleLinkClick(this.Consolidation);
      this.handleLinkClick(this.Air);
      this.handleLinkClick(this.Sea);
      this.handleLinkClick(this.Road);
    }
  }

  handleLinkClick(panel: MatExpansionPanel) {
    if (this.isMobile) {
      panel.close();
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (isPlatformBrowser(this.platformId)) {
      this.isMobile = window.innerWidth < 1200;
    }
  }

  gotoTop(event: MouseEvent): void {
    event.preventDefault();
    if (isPlatformBrowser(this.platformId)) {
      window.scrollTo(0, 0);
    }
  }

  isShown: boolean = false;

  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (isPlatformBrowser(this.platformId)) {
      this.isShown = window.pageYOffset > 300;
    }
  }

  scrollToTop() {
    const scrollDuration = 1000;
    const start = isPlatformBrowser(this.platformId) ? window.scrollY : 0;
    const startTime = performance.now();

    const scroll = (currentTime: number) => {
      const timeElapsed = currentTime - startTime;
      const progress = Math.min(timeElapsed / scrollDuration, 1);
      const ease = this.easeInOutCubic(progress);
      if (isPlatformBrowser(this.platformId)) {
        window.scrollTo(0, start - start * ease);
      }

      if (timeElapsed < scrollDuration) {
        requestAnimationFrame(scroll);
      }
    };

    requestAnimationFrame(scroll);
  }

  easeInOutCubic(t: number): number {
    return t < 0.5 ? 4 * t * t * t : 1 - Math.pow(-2 * t + 2, 3) / 2;
  }
}
