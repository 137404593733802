<div class="mega-menu">

    <div class="col-xl-4 col-lg-4 col-xxl-4">
      <!-- <img src="../../../../assets/imgs/Rectangle 87.png" class="img-fluid" alt="Road Freight" *ngIf="!isVideoVisible" /> -->

      <video id="videoFrame" class="img-fluid" controls (mouseenter)="playVideo($event)" (mouseleave)="pauseVideo($event)" playsinline>
        <source src="../../../../assets/Conexa Update 02.mp4" type="video/mp4">
        Your browser does not support the video tag.
      </video>
    </div>

    <div class="col-xl-4 col-xxl-4 pe-5 border-right d-flex flex-column justify-content-between">
      <!-- <div class="row"> -->
        <h3>We understand the ever changing needs of our customers</h3>
      <!-- </div> -->
      <!-- <div class="row"> -->
        <p>we provide a high level of service dedicated to fulfilling all your shipping requirements</p>
      <!-- </div> -->
      <!-- <div class="row"> -->
        <a class="btn btn-outline-primary w-50" href="https://youtu.be/QXPqypMrq6s" target="_blank">Watch Video</a>
      <!-- </div> -->

    </div>

    <div class="mega-menu-column w-25" >
      <ul>
        <li><a class="fs-6" routerLink="/about/mission-statement" (click)="closeMenu()">Mission Statement</a></li>
        <li><a class="fs-6" routerLink="/about/accreditations" (click)="closeMenu()">Accreditations</a></li>
        <li><a class="fs-6" routerLink="/about/our-partners" (click)="closeMenu()">Our Partners</a></li>
        <li><a class="fs-6" routerLink="/about/why-intercargo" (click)="closeMenu()">Why intercargo?</a></li>
        <li><a class="fs-6" routerLink="/about/how-to-ship" (click)="closeMenu()">How to Ship?</a></li>
      </ul>
    </div>

</div>