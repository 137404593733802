<app-rightimage [rightImageData]="rightImageInfo"></app-rightimage>

<section class="container-two my-5">
  <div class="row">
    <div class="col-lg-3 col-md-12 order-lg-1 order-2 d-flex flex-column align-items-center align-items-lg-start">
      <!-- <p class="fw-medium back-button d-flex align-items-center justify-content-start" id="back"
        style="cursor: pointer; font-size: 1em !important" routerLink="">
        <i class="bi bi-chevron-left fw-bolder h2-icon me-2"></i>
        Go Back to Home
      </p> -->
      <!-- <div class="exploreDiv text-start text-md-start p-4" style="width: 90% !important" #exploreSide>
        <h1 class="fw-medium">News Filters</h1>
        <div style="margin-top: 15%"></div>
        <label>Category</label>
        <div class="my-3">
          <select aria-label="Select a country" class="form-select" style="height: 50px !important">
            <option value="N/A" selected>All Departments</option>
            <option value="CA">Canada</option>
            <option value="UK">United Kingdom</option>
            <option value="AU">Australia</option>
            <option value="PK">Pakistan</option>
            <option value="IN">India</option>
            <option value="CN">China</option>
          </select>
        </div>

        <label class="mt-5">Location</label>
        <div class="my-3 w-100">
          <select aria-label="Select a country" class="form-select"
            style="height: 50px !important; vertical-align: middle !important">
            <option value="N/A" selected>All Locations</option>
            <option value="CA">Canada</option>
            <option value="UK">United Kingdom</option>
            <option value="AU">Australia</option>
            <option value="PK">Pakistan</option>
            <option value="IN">India</option>
            <option value="CN">China</option>
          </select>
        </div>
      </div> -->
    </div>

    <div class="col-lg-1 d-none d-lg-block"></div>

    <div class="col-lg-7 col-md-12 order-lg-3 order-2" style="
        border-left: 1.6px solid #38383867;
        border-radius: 2px;
        padding-left: 3%;
      ">
      <div class="p-4" #question>
        <div class="faq-container">
          <div class="faq-header">
            <h1 class="text-primary">News & Media</h1>
          </div>
          <div *ngIf="loading" class="spinner-border text-primary d-flex justify-content-center align-items-center"
            role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
          <div *ngIf="!loading">
            <div class="card mb-3" *ngFor="let article of articles; let i = index">
              <div class="row">
                <div class="col-md-6 d-flex flex-column justify-content-between">
                  <div class="card-body text-start">
                    <span class="date">{{ article.date }}</span>
                    <h1 class="title" style="color: var(--txtDark)">
                      {{ article.title }}
                    </h1>
                    <p class="text-primary text-start fw-bold me-3 me-md-4 me-xl-4 me-xxl-5 pe-lg-2 pe-0" style="
                        font-size: 1.2em !important;
                        padding: 0 !important;
                        margin: 0 !important;
                      ">
                      Source: {{ article.source.uri }}
                    </p>
                    <a (click)="navigateToBlog('/blog-details', article)"
                      class="read-more d-flex align-items-center my-3 text-primary">
                      Read more <i class="bi bi-arrow-right-short fs-2"></i>
                    </a>
                  </div>
                </div>
                <div class="col-md-6 h-50 text-center text-lg-start">
                  <img [src]="article.image" class="image" alt="Image description" (error)="onImageError($event, i)" />
                </div>
              </div>
            </div>
            <div class="col-12 d-flex justify-content-start py-5 pagination-container">
              <nav aria-label="Page navigation example">
                <ul class="pagination">
                  <li class="page-item" [class.disabled]="currentPage === 1">
                    <a class="page-link" (click)="changePage(currentPage - 1)" aria-label="Previous">
                      <span aria-hidden="true">&laquo;</span>
                    </a>
                  </li>
                  <li class="page-item" *ngFor="let page of getPageNumbers()">
                    <a class="page-link" *ngIf="page !== -1" (click)="changePage(page)"
                      [class.active]="currentPage === page">
                      {{ page }}
                    </a>
                    <span class="page-link" *ngIf="page === -1">...</span>
                  </li>
                  <li class="page-item" [class.disabled]="currentPage === getTotalPages()">
                    <a class="page-link" (click)="changePage(currentPage + 1)" aria-label="Next">
                      <span aria-hidden="true">&raquo;</span>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>