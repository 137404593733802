import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-about-menu',
  templateUrl: './aboutmenu.component.html',
  styleUrl: './aboutmenu.component.css'
})
export class AboutMenuComponent {
  @Input() isVisible: boolean = false;

  playVideo(event: Event): void {
    const video = event.target as HTMLVideoElement;
    video.play();
  }

  pauseVideo(event: Event): void {
    const video = event.target as HTMLVideoElement;
    video.pause();
  }
  @Output() closeMenuEvent = new EventEmitter<void>();
  closeMenu() {
    this.closeMenuEvent.emit(); // Emitting an event when the button is clicked
  }

}
