<section class="container-fluid bg-light">
  <div class="row align-items-center justify-content-center p-4">
    <div class="col-lg-4 col-12 order-lg-1 order-2 align-items-lg-start text-lg-start text-md-start text-start my-5">
      <h1 [innerHTML]="rightImageData.heading" class="text-lg-start text-md-start fs-1 fw-semibold"
        style="margin-bottom: 5% !important; line-height: normal;"></h1>
      <p [innerHTML]="rightImageData.description" class="text-lg-start text-md-start"></p>
    </div>

    <div class="col-lg-2 d-none d-lg-block"></div>

    <div class="col-lg-6 col-md-12 order-lg-3 order-1">
      <figure class="image-container text-center">
        <img [src]="rightImageData.img" alt="Logistics solutions" class="img-fluid" />
      </figure>
    </div>
  </div>
</section>

<section class="py-5">
  <div class="row">
    <div
      class="d-flex flex-column flex-lg-row col-lg-12 col-md-12 align-items-start align-items-lg-center justify-content-center gap-5 px-3 py-5 bifaBorder">
      <img [src]="imageSrc" alt="" class="col-lg-4 col-6 bifaImage" style="margin-left: 1%" />
      <h1 class="fs-1 fw-bold text-start col-lg-6" style="margin-left: 1%; line-height: normal;">
        BIFA Standard Trading Conditions <br />
        <span class="fs-6 fw-normal">2021 EDITION (ENGLAND) &copy; BIFA 2021</span>
      </h1>
    </div>
    <div
      class="col-lg-3 col-md-12 order-lg-1 order-2 d-flex flex-column align-items-start text-start align-items-lg-start pt-5 pb-0 pt-lg-5 pb-lg-5">
      <h1 class="text-start fs-2 fw-medium" style="padding-left: 5% !important; line-height: normal;">
        Trading Conditions
      </h1>

      <div class="d-flex row flex-lg-column my-5 align-items-md-start w-100">
        <div class="input-group mb-3 d-flex align-items-center text-lg-start text-md-start">
          <ul class="link-list w-100 p-0" #faqList>
            <li (click)="getFAQs('definitions')" [class.active]="activeItem === 'definitions'">
              Definitions and Application
            </li>
            <li (click)="getFAQs('company')" [class.active]="activeItem === 'company'">
              The Company
            </li>
            <li (click)="getFAQs('customer')" [class.active]="activeItem === 'customer'">
              The Customer
            </li>
            <li (click)="getFAQs('liability')" [class.active]="activeItem === 'liability'">
              Liability and Limitations
            </li>
            <li (click)="getFAQs('law')" [class.active]="activeItem === 'law'">
              Jurisdiction and Law
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="col-lg-2 d-none d-lg-block"></div>

    <div class="col-lg-6 col-md-12 order-lg-3 order-2 py-lg-5 py-2">
      <div class="p-3 ">
        <div class="faq-container">
          <h1 class="text-primary text-start text-md-start fw-medium fs-2" [innerHTML]="policy.heading"></h1>

          <div class="w-100 text-start" [innerHTML]="policy.description"></div>

          <div class="faq-divider"></div>
        </div>
      </div>
    </div>
  </div>
</section>