import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

declare var YT: any;

@Component({
  selector: 'app-solutions-menu',
  templateUrl: './solutionsmenu.component.html',
  styleUrl: './solutionsmenu.component.css'
})

export class SolutionsMenuComponent {

  @Input() isVisible: boolean = false;

  constructor(private cookieService: CookieService) { }

  setCookies() {
    this.cookieService.set('LAST_RESULT_ENTRY_KEY', 'value', { sameSite: 'None', secure: true });
    this.cookieService.set('remote_sid', 'value', { sameSite: 'None', secure: true });
  }

  // playVideo(video: HTMLVideoElement) {
  //   video.play();
  // }

  // pauseVideo(video: HTMLVideoElement) {
  //   video.pause();
  // }

  playVideo(event: Event): void {
    const video = event.target as HTMLVideoElement;
    video.play();
  }

  pauseVideo(event: Event): void {
    const video = event.target as HTMLVideoElement;
    video.pause();
  }

  @Output() closeMenuEvent = new EventEmitter<void>();  // Controls visibility of the mega menu

  closeMenu() {
    this.closeMenuEvent.emit(); // Emitting an event when the button is clicked
  }

  watch() {
    window.open('https://youtu.be/04i5TQy2dqE', '_blank');
  }

}
