import { Component, EventEmitter, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-downloadpdf-thankyou',
  templateUrl: './downloadpdf-thankyou.component.html',
  styleUrls: ['./downloadpdf-thankyou.component.css']
})
export class DownloadpdfThankyouComponent {
  @Output() modalClose: EventEmitter<any> = new EventEmitter<any>();
  title: string = 'Thank You 🎉';
  message: string = '';
  showErrorModal: boolean = false; // New property for error modal


  constructor(private router: Router, private route: ActivatedRoute) {
    this.route.queryParams.subscribe(params => {
      const titleParam = params['title'] || ''; // Get title or fallback to empty string
      const pathParam = params['path'] || ''; 



      // Handle titleParam first
      switch (titleParam.toLowerCase() || pathParam) {
        case 'thankyou':
          this.title = "Thank You for Subscribing! 🎉";
          this.message = 'You have successfully subscribed to our newsletter. Welcome to the Intercargo community! Expect regular updates, exclusive content, and the latest news straight to your inbox.';
          break;
        case 'downloadpdf':
          this.title = 'Thank You 🎉';
          this.message = 'Your request for our company profile has been received. Check your email shortly for more details.';
          break;
        case 'emailthankyou':
          this.title = 'Thank you for your message!';
          this.message = 'We appreciate your query/feedback and will review it shortly. If a response is needed, we’ll get back to you within 1-2 business days.';
          break;
        case 'errormessage':
          this.showErrorModal = true;
          break;
        case 'newregistration':
          this.title = 'Thank You';
          this.message = 'One of our freight experts will reach out to you very soon.';
          break;
        case 'conexathankyou':
          this.title = 'Thank You';
          this.message = 'One of our Conexa experts will reach out to you very soon.';
          break;
        case 'megaMenu':
          this.title = 'Thank You';
          this.message = 'One of our freight experts will reach out to you very soon.';
          break;
        case 'conexa':
          this.title = 'Thank You';
          this.message = 'One of our Conexa experts will reach out to you very soon.';
          break;
        default:
          this.showErrorModal = false;
          break;
      }

    });
  }




  handleMegaMenu() {
    this.title = 'Thank You';
    this.message = 'One of our freight experts will reach out to you very soon.';
  }

  handleConexa() {
    this.title = 'Thank You';
    this.message = 'One of our Conexa experts will reach out to you very soon.';
  }
  closeModal($event: any) {
    if (this.route.snapshot.queryParams['path'] === 'conexa') {
      this.router.navigate([{ outlets: { modal: null } }]); 
      setTimeout(() => {
        this.router.navigate(['/MyIntercargo/conexa']);
      }, 0);
      return; 
    }
    if (this.route.snapshot.queryParams['path'] === 'megaMenu') {
      this.router.navigate([{ outlets: { modal: null } }]); 
      setTimeout(() => {
        this.router.navigate(['/']);
      }, 0);
      return; 
    }
  
    this.router.navigate([{ outlets: { modal: null } }]);
    this.modalClose.next($event);
    this.showErrorModal = false; 
  }
  

  SignUp(): void {
    this.router.navigate([{ outlets: { modal: null } }]);
    setTimeout(() => {
      this.router.navigate(['/members-hub/open-an-account'], { queryParams: { path: 'megaMenu' } });
    }, 0);
  }

}
