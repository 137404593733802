<!-- <div class="bg-light"> -->

<div class="container-fluid bg-light ">
  <div class="container">
    <div class="row ms-lg-3 ms-md-0 ms-0">
      <p
        class="fw-medium d-flex align-items-center justify-content-start py-0 my-0 mb-5"
        style="cursor: pointer; " (click)="redirect('')">
        <button
          class="hover-bg-blue rounded-5 border-0 bg-white me-3 pe-2 py-1 d-flex align-items-center justify-content-center ">
          <i class="bi bi-chevron-left text-primary"></i>
        </button>
        <span>Back to Home Page</span>
      </p>
    </div>
    <div class="row gap-xl-2 gap-lg-2 gap-xxl-5 justify-content-center">
      <div class="col-lg-9 col-12">
        <input class="form-control w-100" placeholder="search here"
          [(ngModel)]="searchedString" (keydown.enter)="searchBtnClicked()">
      </div>
      <button class="btn btn-primary col-lg-2 col-12 mt-3 mt-lg-0"
        (click)="searchBtnClicked()">
        Search
      </button>
    </div>
  </div>
</div>
<!-- </div> -->

<div class="container-fluid">
  <!-- [checked]="task().completed" [indeterminate]="partiallyComplete()" -->
  <!-- (change)="update($event.checked)" -->

  <div class="row">

    <div class="col-lg-3 col-12 order-1">
      <span>Refine your search</span>
      <hr />
      <div class="form-check ms-lg-3 d-flex mt-4 gap-4 fs-5"
        *ngFor="let filter of filters">
        <input class="form-check-input my-auto" type="checkbox" value
          title="{{filter.name}}" [(ngModel)]="filter.checked"
          (change)="filterSearch()">
        <label class="form-check-label my-auto fs-6" for="flexCheckDefault">
          {{filter.name}}
        </label>
      </div>
      <!-- <section *ngIf="filters.length > 2"> -->
      <!-- </section> -->
      <!-- <div *ngFor="let filter in filters"> -->
      <!-- <mat-checkbox checked="true" *ngFor="let filter in filters"> {{filter.name}} </mat-checkbox> -->
      <!-- </div> -->
    </div>

    <div class="col-lg-9 col-12 order-2">
      <span class="text-dark"> Showing {{ startItemIndex }} - {{ endItemIndex }}
        out of {{ searchResults.length }}
        for <span class="text-primary">{{searchedString}} </span></span>
      <hr />
      <div *ngFor="let result of searchResultsPerPage">
        <!-- <div *ngFor="let keys of result.keywords"> -->
        <div class="rounded searchItem px-lg-5 py-lg-3 mt-lg-3 mt-5"
          (click)="redirect(result.url)">
          <h2 class="text-primary text-start">{{result.heading}}</h2>
          <!-- <span class="badge rounded-pill text-bg-primary">Primary</span> -->
          <p class="description py-0 ps-3 my-0 text-start"
            [innerHTML]="result.description"></p>
        </div>
        <hr />
        <!-- </div> -->
        <!-- <hr /> -->
      </div>

    </div>
  </div>

  <div class="pagination-controls">
    <button class="btn active border page-number" (click)="previousPage()"
      [disabled]="pageNumber === 1" *ngIf="pagesBtnsToShow.length!=0"><i
        class="bi bi-chevron-double-left"></i></button>
    <!-- <span>Page {{ pageNumber }} of {{ totalPages }}</span> -->

    <button class="btn  border page-number "
      *ngFor="let pageBtn of pagesBtnsToShow "
      [ngClass]="{'active': pageBtn === pageNumber}"
      (click)="goToPage(pageBtn)">
      {{ pageBtn }}
    </button>

    <button class="btn active border page-number" (click)="nextPage()"
      [disabled]="pageNumber === totalPages"
      *ngIf="pagesBtnsToShow.length!=0"><i
        class="bi bi-chevron-double-right"></i></button>
  </div>

  <!-- <div class="col-12 d-flex justify-content-start py-5 pagination-container">
        <nav aria-label="Page navigation example">
          <ul class="pagination">
            <li class="page-item" [class.disabled]="currentPage === 1">
              <a class="page-link" (click)="changePage(currentPage - 1)" aria-label="Previous">
                <span aria-hidden="true">&laquo;</span>
              </a>
            </li>
            <li class="page-item" *ngFor="let page of getPageNumbers()">
              <a class="page-link" *ngIf="page !== -1" (click)="changePage(page)"
                [class.active]="currentPage === page">
                {{ page }}
              </a>
              <span class="page-link" *ngIf="page === -1">...</span>
            </li>
            <li class="page-item" [class.disabled]="currentPage === getTotalPages()">
              <a class="page-link" (click)="changePage(currentPage + 1)" aria-label="Next">
                <span aria-hidden="true">&raquo;</span>
              </a>
            </li>
          </ul>
        </nav>
      </div> -->
</div>